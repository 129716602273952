import React from "react";
import Footer from "../../components/Common/footer";
import Header from "../../components/Common/header/header";

function BlogAndNews() {
  return (
    <div className="bg-[#F8F8F8] w-full">
      <div className="absolute z-50 w-full">
        <Header searchTextChangeColor={true} />
      </div>
      <div className="flex justify-center pt-48 md:max-xl:pt-48 ">
        <div className="w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] mb-16">
          <div className="flex pv:max-xl:flex-col pv:max-xl:items-center pv:max-xl:gap-5 h-full w-full justify-between">
            {/* left section */}
            <div className="w-[100%] pv:max-xl:w-[100%] bg-white rounded-[30px] p-10 flex justify-between">
              <div className="w-[20%] bg-red-200 h-64 flex-col">
                <p className="text-[#F56177] font-bold text-[35px] uppercase text-start underline underline-offset-1">
                  tin tức/ sự kiện
                </p>
              </div>
              <div className="w-[70%] bg-yellow-200 h-64">
                <p className="text-[#F56177] font-bold text-[35px] uppercase text-start underline underline-offset-1">
                  sự kiện nổi bật
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default BlogAndNews;
