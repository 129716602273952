import React from "react";
import classNames from "classnames";

interface ServiceCardProps {
  packageType: string;
  packageColor: string;
  price: number;
  imageUrl: string;
  originPrice: number;
  pricePerMonth: number;
  isExpanded: boolean;
  onClick?: () => void;
  onDoubleClick?: () => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  packageType,
  packageColor,
  imageUrl,
  price,
  originPrice,
  pricePerMonth,
  isExpanded,
  onClick,
  onDoubleClick,
}) => {
  const formattedPrice = price.toLocaleString("de-DE");
  const formattedOriginPrice = originPrice.toLocaleString("de-DE");
  const formattedPricePerMonth = pricePerMonth.toLocaleString("de-DE");

  const renderFeature = (text: string) => (
    <div className="flex items-center gap-2">
      <img
        className="h-[34px] object-contain"
        src="/logo/icon_service_package.png"
        alt=""
      />
      <p className="text-white text-[16px] font-bold">{text}</p>
    </div>
  );

  return (
    <div onDoubleClick={onDoubleClick}>
      <div
        className={classNames(
          "flex flex-col justify-between rounded-[30px] py-4 w-60 dvmax:max-dhmin:w-[300px] shadow-lg transition-all duration-300 cursor-pointer relative",
          {
            "h-[450px] dvmax:max-dhmin:h-[520px]": isExpanded,
            "h-[300px] dvmax:max-dhmin:h-[400px]": !isExpanded,
          }
        )}
        style={{
          backgroundColor: packageColor,
          boxShadow: "0px 4.62px 10.28px 5.78px rgba(0, 82, 117, 0.25)",
        }}
        onClick={onClick}
        aria-expanded={isExpanded}
      >
        <div>
          <p className="font-bold text-[20px] text-center dvmax:max-dhmin:text-[27px] text-white mb-2 uppercase">
            Gói {packageType} tháng
          </p>
          <div
            className="bg-white min-w-fit max-w-fit pr-5 pl-4 rounded-e-[90px]"
            style={{
              boxShadow: "0px 4.62px 4.62px 0px rgba(0, 82, 117, 0.25)",
            }}
          >
            <div
              className="font-bold text-[56px] mb-[-20px]"
              style={{
                color: packageColor,
              }}
            >
              {formattedPrice}
            </div>
            <p
              className="font-medium text-[17px] flex gap-2 "
              style={{ color: packageColor }}
            >
              Giá gốc:{" "}
              <span className="line-through">{formattedOriginPrice}</span>
            </p>
          </div>

          <div className="flex justify-center mt-5">
            <img
              src={`character_service_package/${imageUrl}.png`}
              alt={`service_package_${imageUrl}`}
              className="w-24 dvmax:max-dhmin:w-[120px] object-contain"
            />
          </div>
        </div>
        {pricePerMonth === 0 && (
          <img
            className="w-[190px] absolute -bottom-1 -right-1 object-contain"
            src="/logo/longlife_badge.png"
            alt=""
          />
        )}

        {!isExpanded && pricePerMonth > 0 && (
          <div className="flex gap-1 justify-center items-end">
            <p className="font-bold text-xl dvmax:max-dhmin:text-[20px] text-white">
              ~{formattedPricePerMonth}
            </p>
            <p className="font-bold text-xl dvmax:max-dhmin:text-[20px] text-white">
              /
            </p>
            <p className="font-medium text-lg dvmax:max-dhmin:text-[17px] text-white">
              1 tháng
            </p>
          </div>
        )}

        {isExpanded && (
          <div className="flex-col items-center gap-10 justify-center px-10">
            {renderFeature("Cập nhật thường xuyên")}
            {renderFeature("An toàn không quảng cáo")}
            {renderFeature("Trải nghiệm không giới hạn bài học tương tác")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
