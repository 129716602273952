const FlipCard = ({
  imageURL,
  title,
  color,
  content,
}: {
  imageURL?: string;
  title?: string;
  color?: string;
  content?: string;
}) => {
  return (
    <div className="group h-[474px] w-[474px] pv:max-lg:h-[128px] pv:max-lg:w-[343px] [perspective:1000px]">
      <div className="relative h-full w-full rounded-[25px] transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
        {/* Front Face */}
        <div
          className="absolute inset-0 h-full w-full pv:max-lg:flex-row pv:max-lg:gap-5 flex flex-col items-center justify-center rounded-[25px] [backface-visibility:hidden]"
          style={{
            backgroundColor: color,
          }}
        >
          <img
            className="object-contain h-1/2 pv:max-lg:h-[77px] cursor-pointer"
            src={imageURL}
            alt=""
          />
          <div className="w-[315px] pv:max-lg:w-[140px]">
            <p
              className={
                "pv:max-lg:text-[22px] text-[50px] pv:max-lg:leading-[40px] leading-[60.15px] font-[700] text-center text-white mt-4"
              }
            >
              {title}
            </p>
          </div>
        </div>

        {/* Back Face */}
        <div
          style={{
            backgroundColor: color,
          }}
          className="absolute inset-0 h-full w-full flex items-center rounded-[25px] px-10 text-center text-white [transform:rotateY(180deg)] [backface-visibility:hidden]"
        >
          <p className="text-[22px] pv:max-lg:text-[14px] font-semibold text-justify">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
