import check from "../../assets/Library/check.png";

const ValueCard = ({
  imageURL,
  title,
  items,
  nextClick,
  prevClick,
}: {
  imageURL: string;
  title: string;
  prevClick: () => void;
  nextClick: () => void;
  items: Array<{
    title: string;
  }>;
}) => {
  return (
    <div className="flex pv:max-dv:flex-col pv:max-dv:items-center justify-between items-start w-full gap-10 ">
      <img
        className="items-center pv:max-md:w-full pv:max-md:object-contain pv:max-md:h-auto w-[585px] h-[555px] object-fill"
        src={imageURL}
        alt=""
      />
      <div className="flex flex-col w-full justify-start">
        <div className="flex justify-between items-center">
          <div>
            <h3
              className={`text-[80px] pv:max-sm:text-[30px] dv:max-dvmax:text-[70px] font-[800] text-[#142548]`}
            >
              {title}
            </h3>
          </div>
          <div className="w-auto h-auto flex gap-5 pv:max-sm:gap-2">
            <img
              className="h-[64px] pv:max-sm:w-[26px] object-contain"
              src="/library_img/icon/icon_back.png"
              alt="Previous"
              onClick={prevClick}
            />
            <img
              className="h-[64px] pv:max-sm:w-[26px] object-contain"
              src="/library_img/icon/icon_next.png"
              alt="Next"
              onClick={nextClick}
            />
          </div>
        </div>
        <div className={" grid grid-cols-2 pv:max-md:grid-cols-1 gap-6"}>
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className={"flex items-center justify-start mb-6"}
              >
                <div
                  className={
                    "w-[59px] pv:max-sm:w-[40px] h-auto bg-[#142548] rounded-md"
                  }
                >
                  <img
                    className="items-center object-contain"
                    src={check}
                    alt=""
                    style={{
                      //   zIndex: 10,
                      width: "59px",
                    }}
                  />
                </div>
                <p
                  className={
                    "ml-6 font-[400] dv:max-dvmax:text-[26px] pv:max-sm:text-[14px] pv:max-sm:leading-[18px] text-[30px] w-[483px] leading-[36.31px]"
                  }
                >
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ValueCard;
