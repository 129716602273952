import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';

// Interface cho Referral Code
interface Referral {
    name: string;
    referralCode: string;
    usageCount: number;
    total: number;
    appStoreLink: string;
    appStoreDownloads: number;
    googlePlayLink: string;
    googlePlayDownloads: number;
  }

// Interface cho Context
interface ReferralContextType {
  referrals: Referral[];
  incrementReferralUsage: (code: string, amount: number) => void;
}

// Tạo Context
const ReferralContext = createContext<ReferralContextType | undefined>(undefined);

// Component provider để bao quanh các thành phần cần truy cập dữ liệu
export const ReferralProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [referrals, setReferrals] = useState<Referral[]>([
    {
        name: 'User 1',
        referralCode: 'ABC1234',
        usageCount: 0,
        total:0,
        appStoreLink: 'https://appstore.link/app1',
        appStoreDownloads: 0,
        googlePlayLink: 'https://play.google.com/app1',
        googlePlayDownloads: 0,
      },
      {
        name: 'User 2',
        referralCode: 'XYZ456',
        usageCount: 0,
        total:0,
        appStoreLink: 'https://appstore.link/app2',
        appStoreDownloads: 7,
        googlePlayLink: 'https://play.google.com/app2',
        googlePlayDownloads: 6,
      },
      {
        name: 'User 3',
        referralCode: 'DEF789',
        usageCount: 0,
        total:0,
        appStoreLink: 'https://appstore.link/app3',
        appStoreDownloads: 10,
        googlePlayLink: 'https://play.google.com/app3',
        googlePlayDownloads: 10,
      },
  ]);

  // Hàm cập nhật số lượng referral code khi người dùng nhập mã
  const incrementReferralUsage = (code: string, amount: number) => {

    setReferrals(prevReferrals => {
        
        
        
            const updatedReferrals = prevReferrals.map(referral =>
                referral.referralCode === code
                  ? { ...referral, usageCount: referral.usageCount + 1 , total:referral.total+amount}
                  : referral
              );
              console.log("Updated referrals:", updatedReferrals);
        // Lưu vào localStorage
    localStorage.setItem('referralsabcd', JSON.stringify(updatedReferrals));
        return [...updatedReferrals];
        
              });

      
  };

  

  useEffect(() => {
    const savedReferrals = localStorage.getItem('referralsabcd');
    if (savedReferrals) {
      setReferrals(JSON.parse(savedReferrals));
    }
  }, []);

  return (
    
    <ReferralContext.Provider value={{ referrals, incrementReferralUsage }}>
      {children}
    </ReferralContext.Provider>
  );
};

// Hook để dễ sử dụng ReferralContext
export const useReferral = () => {
  const context = useContext(ReferralContext);
  if (!context) {
    throw new Error('useReferral must be used within a ReferralProvider');
  }
  return context;
};
