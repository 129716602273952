import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";

function Footer({ className }: { className?: string }) {
  return (
    <div
      className={`w-[100%] justify-between bg-[#142548] h-auto flex text-white p-16 pb-36 pv:max-ph:p-8 ph:max-md:p-4 md:max-xl:p-6 pv:max-md:flex-col pv:max-md:gap-6 md:max-xl:gap-4 pv:max-md:items-center  ${className}`}
    >
      <div className="pv:max-md:flex pv:max-md:justify-start pv:max-md:w-full">
        <img
          src="logo/logo_footer.png"
          className="object-contain md:max-dh:w-[350px]"
          alt=""
        />
      </div>
      <div className="flex w-[70%] h-60 justify-between  pv:max-xl:h-fit pv:max-md:w-full pv:max-xl:flex-wrap pv:max-xl:gap-4">
        <div className=" dv:max-dh:hidden flex flex-col justify-between pv:max-ph:flex-row pv:max-sm:justify-between pv:max-sm:w-full sm:max-dh:w-[25%]">
          <ul className="uppercase xl:max-dv:space-y-4 text-base dhmin:max-dh:text-[25px] dhmin:max-dh:font-bold flex flex-col h-auto justify-between w-full sm:max-dh:w-[100%] pv:max-md:justify-start pv:max-md:gap-3">
            <a className="font-bold pv:max-xl:normal-case" href="#">
              Về chúng tôi
            </a>
            <a className="font-bold pv:max-xl:normal-case" href="#">
              Về Whykids
            </a>
            <a className="font-bold pv:max-xl:normal-case" href="#">
              Gói dịch vụ
            </a>
          </ul>
          <ul className="uppercase xl:max-dv:space-y-5 text-base dhmin:max-dh:text-[25px] dhmin:max-dh:font-bold flex flex-col h-auto justify-between w-full sm:max-dh:w-[100%] pv:max-md:justify-start pv:max-md:gap-3">
            <a
              className="font-bold pv:max-xl:normal-case"
              href="/library-introduction"
            >
              Thư viện học liệu
            </a>
            <a className="font-bold pv:max-xl:normal-case" href="/blog-news">
              Blog/ tin tức
            </a>
            <a className="font-bold pv:max-xl:normal-case" href="#">
              Hỗ trợ
            </a>
          </ul>
        </div>

        <ul className=" uppercase xl:max-dv:space-y-4 pv:max-dv:hidden text-base dhmin:max-dh:text-[25px] dhmin:max-dh:font-bold flex flex-col h-auto justify-between w-full bg-green-40 sm:max-dh:w-[30%] pv:max-md:justify-start pv:max-md:gap-3">
          <a className="font-bold pv:max-xl:normal-case" href="#">
            Về chúng tôi
          </a>
          <a className="font-bold pv:max-xl:normal-case" href="#">
            Về Whykids
          </a>
          <a className="font-bold pv:max-xl:normal-case" href="#">
            Gói dịch vụ
          </a>
          <a
            className="font-bold pv:max-xl:normal-case"
            href="/library-introduction"
          >
            Thư viện học liệu
          </a>
          <a className="font-bold pv:max-xl:normal-case" href="/blog-news">
            Blog/ tin tức
          </a>
          <a className="font-bold pv:max-xl:normal-case" href="#">
            Hỗ trợ
          </a>
        </ul>

        <div className="flex flex-col justify-between w-[20%] font-medium space-y-3 dhmin:max-dh:text-[25px]  pv:max-ph:w-[48%] ph:max-md:w-[30%] md:max-xl:w-[61%]">
          <a href="#" className="uppercase font-bold  ">
            Liên hệ
          </a>
          <a>Địa chỉ: 24 Tôn Đức Thắng, Đống Đa, Hà Nội</a>
          <a>
            Email: <br /> hotline@whycorp.vn
          </a>
          <div>Hotline: 0901762991</div>
        </div>
        <div className="flex flex-col justify-between dhmin:max-dh:text-[25px] w-[20%] pv:max-ph:w-full ph:max-md:w-[30%] md:max-xl:w-full gap-2">
          <a href="#" className="uppercase font-bold">
            Điều khoản
          </a>
          <div>
            <a href="/term">Điều khoản</a> <br />{" "}
            <a href="/policy">Bảo mật thông tin</a>
          </div>
          <div className="uppercase font-bold flex flex-col gap-2">
            <a href="">Kết nối với chúng tôi</a>
            <div className="flex space-x-2">
              <div className="rounded-full bg-white p-[6px]">
                <FaFacebook color="#142548" size={25} />
              </div>
              <div className="rounded-full bg-white p-[6px]">
                <FaInstagram color="#142548" size={25} />
              </div>
              <div className="rounded-full bg-white p-[6px]">
                <FaYoutube color="#142548" size={25} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
