import React, { useState } from "react";
import Footer from "../../components/Common/footer";
import backgroundImg from "../../assets/login/bg_login.png";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Common/header/header";
import ServiceCard from "../../components/Common/service_package_component/service_card";

const cardsPackage = [
  {
    value: 1,
    packageColor: "#65AFFF",
    imageUrl: "baby1",
    packageType: "1",
    price: 199000,
    orignalPrice: 499000,
    pricePerMonth: 49000,
    id:"clt83tfo6000108la76z54ev0"
  },
  {
    value: 2,
    packageColor: "#9FD356",
    imageUrl: "baby2",
    packageType: "3",
    orignalPrice: 999000,
    price: 499000,
    pricePerMonth: 166000,
    id:"clt83waa0000208laeplah3jv"
  },
  {
    value: 3,
    packageColor: "#F7BF57",
    imageUrl: "baby3",
    packageType: "6",
    orignalPrice: 1299000,
    price: 899000,
    pricePerMonth: 149000,
    id:"clt83zmsi000508la3s779oa0"
  },
  {
    value: 4,
    packageColor: "#F56177",
    imageUrl: "baby4",
    packageType: "12",
    orignalPrice: 1799000,
    price: 899000,
    pricePerMonth: 0,
    id:"clt83z8jh000408lahzfcedc8"
  },
];
export { cardsPackage };

function ServicePackage() {
  const [expandedCard, setExpandedCard] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleCardDoubleClick = (card: {
    value: number;
    packageColor: string;
    imageUrl: string;
    packageType: string;
    price: number;
    pricePerMonth: number;
  }) => {
    console.log("Card details:", card);
    // Thêm các logic khác nếu cần
    navigate("/checkout-order", { state: { selectedCard: card } });
    // navigate("/");
  };

  const handleCardClick = (cardType: string) => {
    setExpandedCard(expandedCard === cardType ? null : cardType);
  };
  return (
    <div
      className="w-[100%] relative"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="absolute z-50 w-full">
        <Header />
      </div>
      <div className="pt-48">
        <div className="flex justify-center items-center h-[45rem] pv:max-sm:h-fit pv:max-sm:pb-10 gap-8 lg:max-dh:gap-28 md:max-xl:h-fit md:max-xl:pb-6  flex-wrap">
          {cardsPackage.map((card) => (
            <ServiceCard
              key={card.packageType}
              packageColor={card.packageColor}
              imageUrl={card.imageUrl}
              originPrice={card.orignalPrice}
              packageType={card.packageType}
              price={card.price}
              pricePerMonth={card.pricePerMonth}
              isExpanded={expandedCard === card.packageType}
              onClick={() => handleCardClick(card.packageType)}
              onDoubleClick={() => handleCardDoubleClick(card)} // Truyền toàn bộ thông tin thẻ vào
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ServicePackage;
