import React from "react";
import { cardsPackage } from "../../../pages/servicePackage";

interface PackageOption {
  value: number;
  label: string;
  price: number;
}

interface PackageRadioButtonProps {
  selectedValue: number;
  onChange: (value: number, label: string, price: number) => void;
}

const PackageRadioButton: React.FC<PackageRadioButtonProps> = ({
  selectedValue,
  onChange,
}) => {
  const handleChange = (value: number) => {
    const selectedPackage = cardsPackage.find(
      (option) => option.value === value
    );
    if (selectedPackage) {
      onChange(
        selectedPackage.value,
        `Gói ${selectedPackage.packageType} tháng`, // Create a dynamic label
        selectedPackage.price
      );
    }
    console.log(selectedPackage, "22");
  };

  return (
    <div className="w-full">
      {cardsPackage.map((option) => (
        <label
          key={option.value}
          className={`flex justify-between items-center border-2 border-[#142548] w-full px-10 py-3 my-2 rounded-[20px] cursor-pointer`}
        >
          <div className="flex items-center space-x-4">
            <div className="relative">
              <input
                type="radio"
                value={option.value}
                checked={selectedValue === option.value}
                onChange={() => handleChange(option.value)}
                className="absolute opacity-0 h-6 w-6"
              />
              <div
                className={`h-6 w-6 rounded-full border-2 flex justify-center items-center transition-all duration-300 ease-in-out ${
                  selectedValue === option.value
                    ? "border-[#142548] bg-[#142548] scale-110"
                    : "border-[#142548]"
                }`}
              >
                <div
                  className={`transition-all duration-300 ease-in-out ${
                    selectedValue === option.value
                      ? "h-3 w-3 bg-white rounded-full scale-100"
                      : "h-6 w-6 bg-[#142548] rounded-full scale-100"
                  }`}
                ></div>
              </div>
            </div>
            <p className="text-[30px] font-[600] flex justify-center items-center gap-3 transition-all duration-300 ease-in-out">
              <img
                className="w-10 object-contain"
                src={`character_service_package/${option.imageUrl}.png`}
                alt=""
              />
              <p>{`Gói ${option.packageType} tháng`}</p>
            </p>
          </div>
          <div className="flex flex-col items-end justify-end">
            <p className=" font-medium transition-all text-[22px] duration-300 ease-in-out line-through">
              {option.orignalPrice.toLocaleString("de-DE")}đ
            </p>
            <p className=" font-bold transition-all duration-300 text-[33px] ease-in-out">
              {option.price.toLocaleString("de-DE")}đ
            </p>
          </div>
        </label>
      ))}
    </div>
  );
};

export default PackageRadioButton;
