import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LearningCard from "../../components/Common/libraryStudy/learningDetailCard";
import ModalLesson from "../../components/Common/libraryStudy/modalLesson";
import MenuLibrary from "../../components/Common/libraryStudy/menu";
import {
  getLessonByIslandID,
  getThumnailGameByKey,
} from "../../services/lesson.service";
import { LoadingOutlined } from "@ant-design/icons";
import { Input, Spin } from "antd";

interface MenuItem {
  key: string;
  label: string;
}

interface Video {
  videoUrl: string;
  thumbnailUrl: string;
}

interface LessonInfo {
  ID: string;
  lessonTitle?: string;
  schoolSectionTitle?: string;
  target?: string;
  subjectId: string;
  description?: string;
  age?: string;
  videos?: Video[];
  games?: string[];
}

function LearningLibraryDetail() {
  const [lessonList, setLessonList] = useState<LessonInfo[]>([]);
  const [itemsMenu, setItemsMenu] = useState<MenuItem[]>([]);
  const [selectedMenuKey, setSelectedMenuKey] = useState("0");
  const [filteredLessons, setFilteredLessons] = useState<LessonInfo[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    lessonTitle: "",
    videoURl: "",
    thumbnailUrl: "",
    age: "",
    targetVideo: "",
    thumnailGameList: [],
  });

  const location = useLocation();
  const navigate = useNavigate();

  const infoIslandDetail = location.state.data;

  const groupByTarget = (lessons: LessonInfo[]): MenuItem[] => {
    const groupedTargets = lessons.reduce((acc, lesson, index) => {
      if (!acc[lesson.target!]) {
        acc[lesson.target!] = {
          key: `${index}`,
          label: lesson.target!,
        };
      }
      return acc;
    }, {} as Record<string, MenuItem>);

    return Object.values(groupedTargets);
  };

  const transformLessonWithVideos = (lessons: LessonInfo[]): LessonInfo[] => {
    const transformedLessons: LessonInfo[] = [];

    lessons.forEach((lesson) => {
      lesson.videos?.forEach((video) => {
        const newLesson = {
          ...lesson,
          videos: [video],
        };
        transformedLessons.push(newLesson);
      });
    });

    return transformedLessons.sort((a, b) => {
      // Check if age is available and handle missing or invalid age values gracefully
      const ageA = a.age ? a.age.split("-").map(Number) : [0, 0];
      const ageB = b.age ? b.age.split("-").map(Number) : [0, 0];

      if (ageA[0] === ageB[0]) {
        return ageA[1] - ageB[1];
      }
      return ageA[0] - ageB[0];
    });
  };

  const handleCardClick = (lesson: any) => {
    const videoUrl = lesson.videos?.[0]?.videoUrl || "";
    const thumbnailUrl = lesson.videos?.[0]?.thumbnailUrl || "";
    setModalContent({
      lessonTitle: lesson.lessonTitle || "",
      videoURl: videoUrl,
      thumbnailUrl: thumbnailUrl,
      age: lesson.age || "",
      targetVideo: lesson.target || "",
      thumnailGameList: lesson.games || [],
    });

    setIsModalVisible(true);
  };

  const handleMenuClick = (key: string) => {
    setSelectedMenuKey(key);
    const selectedMenuLabel = itemsMenu.find((item) => item.key === key)?.label;
    const filtered = lessonList.filter(
      (lesson) => lesson.target === selectedMenuLabel
    );
    setFilteredLessons(filtered);
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
  };

  const handleSearch = () => {
    setIsLoading(true); // Start loading
    const searchQuery = inputValue.toLowerCase();
    const filtered = lessonList.filter(
      (lesson) =>
        lesson.lessonTitle?.toLowerCase().includes(searchQuery) ||
        lesson.target?.toLowerCase().includes(searchQuery) ||
        lesson.schoolSectionTitle
          ?.toLowerCase()
          .includes(inputValue.toLowerCase())
    );
    setFilteredLessons(filtered);
    setIsLoading(false); // End loading
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setInputValue(e.target.value);
    if (value === "") {
      const selectedMenuLabel = itemsMenu.find(
        (item) => item.key === "0"
      )?.label;
      const filtered = lessonList.filter(
        (lesson) => lesson.target === selectedMenuLabel
      );
      setFilteredLessons(filtered);
    } else {
      handleSearch();
    }
  };

  const initData = async () => {
    setIsLoading(true); // Start loading before fetching data
    const lessonData = await getLessonByIslandID(infoIslandDetail.ID);
    const transformedData = transformLessonWithVideos(lessonData.data);
    setLessonList(transformedData);
    const groupedItems = groupByTarget(lessonData.data);
    setItemsMenu(groupedItems);
    setIsLoading(false); // End loading after fetching data
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (selectedMenuKey !== "1") {
      const selectedMenuLabel = itemsMenu.find(
        (item) => item.key === selectedMenuKey
      )?.label;
      const filtered = lessonList.filter(
        (lesson) => lesson.target === selectedMenuLabel
      );
      setFilteredLessons(filtered);
    } else {
      setFilteredLessons(lessonList);
    }
  }, [selectedMenuKey, lessonList]);

  return (
    <div className="bg-[#F9FEFF]">
      <div className="flex justify-center">
        {infoIslandDetail && (
          <img
            className="w-[80%]"
            src={`https://storage.googleapis.com/why_kid_static_content/WEBSITE/HEADER_LIBRARY/${infoIslandDetail.key}.png`}
            alt="Island Detail"
          />
        )}
      </div>

      <div
        className="relative"
        style={{
          backgroundImage: `url("/background/bg_for_school_repeat.png")`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "contain",
        }}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-between w-[90%]">
            <p className="uppercase font-bold text-[45px] text-[#65AFFF]">
              Chủ đề
            </p>
            <div>
              <Input
                variant={"borderless"}
                className="w-[400px] h-[70px] rounded-full bg-[#D1E7FF] px-10 text-[#65AFFF] uppercase text-[24px] font-bold flex items-center"
                placeholder="TÌM KIẾM HỌC LIỆU"
                prefix={
                  <img
                    src="/logo/search_blue.png"
                    alt="Search Icon"
                    className="w-7 h-7 object-contain mr-4"
                  />
                }
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="w-[90%] flex justify-between h-fit mt-10">
            {!inputValue && (
              <div className="w-[24%] h-full">
                <MenuLibrary
                  items={itemsMenu}
                  selectedKey={selectedMenuKey}
                  onMenuClick={handleMenuClick}
                />
                <button
                  onClick={() => navigate(-1)}
                  className="flex mt-5 items-start bg-red-0 gap-4 w-fit px-5 py-3 rounded-[30px]"
                  style={{
                    boxShadow: "0 5px 10px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <img
                    className="h-[27px] w-[27px] object-contain"
                    src="/logo/arrow_undo.png"
                    alt="Go Back"
                  />
                  <p className="text-[20px] font-semibold text-[#65AFFF]">
                    Quay lại
                  </p>
                </button>
              </div>
            )}
            <div
              className={`w-${
                inputValue ? "[100%]" : "[75%]"
              } flex justify-items-stretch flex-wrap gap-5`}
            >
              {isLoading ? ( // Display loading message
                <div className="w-full">
                  <p className="text-blue-500 text-xl font-bold text-center">
                    Loading...
                  </p>
                </div>
              ) : filteredLessons.length > 0 ? ( // Check if there are lessons
                filteredLessons.map((lesson, index) => (
                  <LearningCard
                    colorCardLv1={infoIslandDetail.colors[0]}
                    colorCardLv2={infoIslandDetail.colors[1]}
                    colorCardLv3={infoIslandDetail.colors[2]}
                    handleClick={() => handleCardClick(lesson)} // Open modal with lesson data
                    key={index}
                    lessonTitle={lesson.lessonTitle}
                    age={lesson.age}
                    videoURl={lesson.videos![0]?.videoUrl || ""}
                    thumnail={
                      lesson.videos![0]?.thumbnailUrl ||
                      "/library_img/icon/no-thumbnail.jpg"
                    }
                    thumnailGame={lesson.games}
                    titleVideo={lesson.target}
                  />
                ))
              ) : (
                // Display message if no lessons found
                <div className="w-full">
                  <p className="text-red-500 text-xl font-bold text-center">
                    Không tìm thấy bài học nào phù hợp với từ khóa của bạn.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <img
          className="w-full mt-10"
          src="/library_img/footer/footer_library.png"
          alt=""
        />
        {isModalVisible && (
          <ModalLesson
            isModalVisible={isModalVisible}
            handleCancel={handleCancelModal}
            videoURl={modalContent.videoURl}
            listThumnaiGamelURl={modalContent.thumnailGameList}
            data={modalContent}
          />
        )}
      </div>
    </div>
  );
}

export default LearningLibraryDetail;
