import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/Common/footer";
import OrderSummary from "../../components/Common/payment_components/orderSumary";
import Header from "../../components/Common/header/header";
import { cardsPackage } from "../servicePackage";
import PackageRadioButton from "../../components/Common/payment_components/packageRadioButton";
import OptionPaymentRadioButton from "../../components/Common/payment_components/optionPaymentRadioButton";
import { paymentRequestUser } from "../../services/userServices/paymentUser.service";

function CheckOutOrder() {
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const refcode = queryParams.get('refcode');

    console.log(refcode);
    setRefCode(refcode);
    if (refcode !== null) {
      setInputCode(refcode);
      setDiscounts( [        
        { key: "Mã mặc định", value: 9999 },
      ]);
      setDiscounts((prevDiscounts) => [
        ...prevDiscounts,
        { key: refcode, value: 100000 },
      ]);
    }
    
  }, [location, navigate]);


  const [selectedPackage, setSelectedPackage] = useState(cardsPackage[0]); // Initialize with the first package

  const [inputCode, setInputCode] = useState<string>("");
  const [refCode, setRefCode] = useState<string | null>(null);
  const [hasUsed, setHasUsed] = useState<boolean>(false);
  const [discounts, setDiscounts] = useState([
    { key: "Mã mặc định", value: 9999 },
    // { key: "Ưu đãi 1", value: 50000 },
  ]);

  const packagePrice = selectedPackage.price;

  // Calculate total price based on discounts
  const totalPrice =
    packagePrice - discounts.reduce((acc, discount) => acc + discount.value, 0);

  const handlePackageChange = (value: number, label: string, price: number) => {
    const newPackage = cardsPackage.find((option) => option.value === value);
    if (newPackage) {
      setSelectedPackage(newPackage);
    }
  };

  useEffect(() => {
    if (location.state && location.state.selectedCard) {
      const passIndexPackage = location.state.selectedCard.value;
      console.log("add-to-cart", passIndexPackage);
      setSelectedPackage(cardsPackage[passIndexPackage - 1]);
    } else {
      console.error("Selected card is missing in location state");
    }
  }, [location.state]);

  const handlePayment = async () => {
    const returnUrl='http://localhost:3000/paymentprocess';

    const data = await paymentRequestUser(selectedPackage.id,returnUrl);
    if (data.msg=='Success'){
      window.open(data.data);
    }

    // navigate("/payment", {
    //   state: {
    //     selectedPackage,
    //     discounts,
    //     totalPrice,
    //   },
    // });

    
  };

  const applyDiscountCode = () => {
    alert("Already Apply Discount Code");
  };

  return (
    <div className="bg-[#F8F8F8] w-full">
      <div className="absolute z-50 w-full">
        <Header searchTextChangeColor={true} />
      </div>
      <div className="flex justify-center pt-48 md:max-xl:pt-48 ">
        <div className="w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] mb-16">
          <div className="flex pv:max-xl:flex-col pv:max-xl:items-center pv:max-xl:gap-5 h-full w-full justify-between">
            {/* left section */}
            <div className="w-[75%]  pv:max-xl:w-[100%] bg-white rounded-[30px] p-10">
              <div className="gap-6 flex flex-col">
                <p className="font-bold text-[44px] text-[#142548]">
                  Thông tin liên hệ
                </p>
                <div>
                  <p className="font-semibold text-[23px] text-[#142548]">
                    Họ và tên
                  </p>
                  <input
                    className="w-full h-12 border-2 border-[#142548] rounded-full mt-1 sm:mt-3 px-4  text-[23px] text-[#142548] font-semibold outline-none"
                    placeholder="Họ và tên"
                  ></input>
                </div>
                <div>
                  <p className="font-semibold text-[23px] text-[#142548]">
                    Số điện thoại/ Email:
                  </p>
                  <input
                    className="w-full h-12 border-2 border-[#142548] rounded-full mt-1 sm:mt-3 px-4 text-[23px] text-[#142548] font-semibold outline-none"
                    placeholder="Số điện thoại/ Email"
                  />
                </div>
                <p className="font-bold text-[44px] text-[#142548]">
                  Gói đã chọn
                </p>
                <PackageRadioButton
                  selectedValue={selectedPackage.value}
                  onChange={handlePackageChange}
                />
                <p className="font-bold text-[44px] text-[#142548]">
                  Mã khuyến mãi
                </p>
                <div className="flex justify-between border-2 border-[#142548] rounded-full px-10 py-2">
                  <input 
                    className="w-auto text-[20px] font-semibold uppercase pl-3 text-[#142548] select-none focus:outline-none"
                    placeholder="Mã khuyến mãi"
                    value={ inputCode}
                    onContextMenu={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    onChange={(e) => setInputCode(e.target.value)} 
                    readOnly={refCode !== null}
                  ></input>

                  <button
                    className="bg-[#142548] w-auto font-bold py-1 px-5 rounded-[33px] text-white"
                    onClick={applyDiscountCode}
                  >
                    <p className="text-[20px]">Áp dụng</p>
                  </button>
                </div>

                <p className="font-bold text-[44px] text-[#142548]">
                  Phương thức thanh toán
                </p>
                <OptionPaymentRadioButton />
              </div>
            </div>
            {/* right section */}
            <div className="w-[23%]  pv:max-xl:w-[100%] bg-white rounded-[30px] p-10">
              <OrderSummary
                selectedPackage={selectedPackage}
                discounts={discounts} // Pass discounts as an array of objects
                totalPrice={totalPrice}
                handlePayment={handlePayment}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CheckOutOrder;
