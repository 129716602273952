import axios, { InternalAxiosRequestConfig } from "axios";
import { refreshTokenSchool } from "./tokenSchool.service";
import { API_URL } from "../config";

const axiosConfigSchool= axios.create({
  baseURL:API_URL,  
  headers: {
    "Content-type": "application/json"
  }
}); 

let isRefreshing = false;
let requestsToRefresh: Array<(token: string | null) => void> = [];

// Request Interceptor: Thêm token vào mỗi yêu cầu nếu có
axiosConfigSchool.interceptors.request.use(
  (config) => {

    const token = sessionStorage.getItem('accessTokenSchool'); // Lấy token từ sessionStorage
    if (token) {
      // Nếu có token, thêm vào header Authorization
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config; // Trả về config đã cập nhật
  },
  (error) => {
    // Xử lý lỗi nếu có trước khi yêu cầu được gửi
    return Promise.reject(error);
  }
);

// Response Interceptor: Xử lý lỗi response hoặc token hết hạn
axiosConfigSchool.interceptors.response.use(
  (response) => {
    // Nếu phản hồi thành công, trả về response như bình thường
    return response;
  },
  (error) => {
    console.log('refresh');

    const responseStatus = error.response?.status;
    const config = error.config as InternalAxiosRequestConfig;
    // Nếu phản hồi lỗi, xử lý lỗi, ví dụ: Token hết hạn
    if (error.response && error.response.status === 401) {
      // Xử lý nếu mã lỗi là 401 (Unauthorized)
      const accessToken = sessionStorage.getItem('accessTokenSchool'); 
      const refreshToken = sessionStorage.getItem('refreshTokenSchool'); 


      if (refreshToken==null){
        sessionStorage.removeItem('accessTokenSchool');
        sessionStorage.removeItem('refreshTokenSchool');
        window.location.href = '/';
        return Promise.reject(error);
      }
      if (!isRefreshing){
        isRefreshing = true;

        refreshTokenSchool(accessToken??"",refreshToken??"").then((data)=>{
          sessionStorage.setItem('accessTokenSchool', data.data.accessToken);
          sessionStorage.setItem('refreshTokenSchool', data.data.refreshToken);
          const accessToken2 = sessionStorage.getItem('accessTokenSchool'); 
          const refreshToken2 = sessionStorage.getItem('refreshTokenSchool');
          requestsToRefresh.forEach((callback) => {
            callback(data.data.accessToken);
          });
          
        }).catch(()=>{
            sessionStorage.removeItem('accessTokenSchool');
            sessionStorage.removeItem('refreshTokenSchool');
            window.location.href = '/';
          requestsToRefresh.forEach((cb) => cb(null));
        }).finally(()=>{
          isRefreshing = false;
          requestsToRefresh = [];
        });

        // try {
        //   console.log('refresh 2');
        //   console.log(accessToken);
        //   console.log(refreshToken);
          
        //   const data = await refreshTokenSchool(accessToken??"",refreshToken??"");
        //   console.log(data);

        //   // Cập nhật token 
        //   sessionStorage.setItem('accessTokenSchool', data.token.accessToken);
        //   sessionStorage.setItem('refreshTokenSchool', data.token.refreshToken);

        //   // Thực hiện các yêu cầu chờ đợi khi token được làm mới
        //   requestsToRefresh.forEach((callback) => callback(data));

          
        // } catch (err) {
        //   sessionStorage.removeItem('accessTokenSchool');
        //   sessionStorage.removeItem('refreshTokenSchool');
        //   requestsToRefresh.forEach((cb) => cb(null));
        // } finally {
        //   isRefreshing = false;
        //   requestsToRefresh = [];
        // }
      }

      // Trả về một promise mới để tiếp tục các request đã bị chặn khi token hết hạn
      return new Promise((resolve, reject) => {
        
        requestsToRefresh.push((token) => {
          if (token) {
            const accessTokenNew = sessionStorage.getItem('accessTokenSchool'); 
            const reTokenNew = sessionStorage.getItem('refreshTokenSchool'); 
            // Cập nhật token mới trong header Authorization của request cũ
            config.headers.Authorization = `Bearer ${token}`;
            resolve(axiosConfigSchool(config));
          } 
          reject(error);
          
        });
      });   
    } else {
      sessionStorage.removeItem('accessTokenSchool');
      sessionStorage.removeItem('refreshTokenSchool');
      window.location.href = '/';
    }
    return Promise.reject(error); // Trả về lỗi để tiếp tục xử lý nếu cần
  }
);




export default axiosConfigSchool;