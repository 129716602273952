import React from "react";

interface Package {
  value: number;
  packageType: string;
  price: number;
  pricePerMonth: number;
}
interface Discount {
  key: string;
  value: number;
}
interface OrderSummaryProps {
  selectedPackage: Package;
  discounts: Discount[]; // Updated discounts structure
  totalPrice: number;
  handlePayment?: () => void;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  selectedPackage,
  discounts,
  totalPrice,
  handlePayment,
}) => {
  return (
    <div className="gap-6 flex flex-col">
      <p className="font-semibold text-[45px] text-[#142548]">Đơn hàng</p>
      <div>
        <div className="w-full flex justify-between">
          <p className="font-semibold text-[23px] text-[#142548]">
            Gói {selectedPackage.packageType} tháng
          </p>
          <p className="font-medium text-[23px] text-[#142548]">
            {selectedPackage.price.toLocaleString("de-DE")}
          </p>
        </div>
      </div>

      <div>
        <p className="font-semibold text-[23px] text-[#142548]">Giảm giá</p>
        {discounts.map((discount, index) => (
          <div key={index} className="w-full flex justify-between">
            <p className="font-normal text-[23px] text-[#142548]">
              {discount.key}
            </p>
            <p className="font-medium text-[23px] text-[#F56177]">
              {`-${discount.value.toLocaleString("vi-VN")}`}
            </p>
          </div>
        ))}
      </div>

      {/* Total amount after discounts */}
      <div className="w-full flex justify-between align-bottom items-end">
        <p className="font-semibold text-[34px] text-[#142548]">Tổng cộng</p>
        <p className="font-medium text-[23px] text-[#142548]">
          {totalPrice.toLocaleString("vi-VN")}
        </p>
      </div>

      {handlePayment && (
        <div className="flex justify-end">
          <button
            className="bg-[#142548] font-bold py-1 px-5 rounded-[33px] text-white"
            onClick={handlePayment}
          >
            <p className="text-[20px]"> Thanh toán</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
