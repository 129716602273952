import Footer from "../../components/Common/footer";
import Header from "../../components/Common/header/header";

import backgroundImg from "../../assets/term/term_bg.png";

function PolicyVNPage() {
  return (
    <div className="">
      <div
        className="w-[100%] h-full"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute z-50 w-full">
          <Header />
        </div>

        <div className="w-[100%] h-full justify-center flex pt-48 pb-16">
          <div className="h-[75vh] w-[95%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] rounded-[33px] justify-center items-center scroll-smooth bg-[#FFFFFF] bg-opacity-40 px-[16px] py-[36px] ">
            <div className="items-center justify-center flex">
              <h1 className="font-[800] px-[50px] text-[48px] uppercase text-[#142548]">
                chính sách bảo mật
              </h1>
            </div>
            <div
              className=" h-[90%] overflow-y-auto [&::-webkit-scrollbar]:mr-[100px] [&::-webkit-scrollbar]:w-2
          [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-[#9DCBFC]
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-[#2A3E5E]"
            >
              <p className="text-[48px] px-[50px]"></p>
              <div
                className={`font-svn-gilroy w-full text-xl font-medium leading-[normal] tracking-[0px] text-[#142548]  px-[16px] sm:px-[50px] `}
              >
                <span>
                  <ul className="list-disc pl-10 font-medium underline decoration-2 space-y-1">
                    <li className="">Điều khoản</li>
                    <li>Nguyên tắc cư xử</li>
                    <li>Nguyên tắc đặt tên</li>
                    <li> Nguyên tắc WK</li>
                    <li>Nguyên tắc xử lý</li>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">1. Giới Thiệu:</p>
                  <div className="h-6" />
                  <ul className="list-disc pl-10 space-y-1">
                    <li>
                      <span className="font-bold">{"Whykids "}</span>
                      ("WK" hoặc "Sản Phẩm" hay “Ứng Dụng”) là tên gọi chính
                      thức của ứng dụng điện tử được phát hành duy nhất tại Việt
                      Nam bởi Công Ty Cổ Phần Vì Sao.
                    </li>
                    <li>
                      Why Kids được thiết kế dành cho trẻ em từ 3-7 tuổi với mục
                      đích mang đến những bài học hấp dẫn và bổ ích thông qua
                      các video sinh động. Sau mỗi bài học, trẻ sẽ được tham gia
                      vào các trò chơi ôn tập thú vị để củng cố kiến thức và
                      phát triển kỹ năng. WK tuân thủ theo quy định của pháp
                      luật Việt Nam.
                    </li>
                    <li>
                      Trước khi sử dụng sản phẩm, Bạn cần đọc, hiểu rõ và đồng ý
                      với toàn bộ Điều Khoản Sử Dụng này (bao gồm cả yêu cầu về
                      độ tuổi) và Chính Sách Bảo Mật <strong>Thông Tin</strong>,
                      đặc biệt là những điều khoản miễn trừ hoặc hạn chế trách
                      nhiệm của Whykids, những điều khoản hạn chế đối với quyền
                      lợi của Bạn cũng như những điều khoản riêng biệt khác.
                    </li>

                    <li>
                      Trường hợp Bạn không đồng ý với bất kỳ điều khoản, điều
                      kiện nào trong <strong>Điều Khoản Sử Dụng</strong> này,
                      Bạn vui lòng không sử dụng WK và ngay lập tức đóng, gỡ bỏ
                      hoàn toàn Sản Phẩm ra khỏi thiết bị của Bạn.
                    </li>
                    <li>
                      Việc Bạn tham gia, sử dụng Trò Chơi được hiểu là Bạn đồng
                      ý với toàn bộ các điều khoản, điều kiện của Điều Khoản Sử
                      Dụng này và Chính Sách Bảo Mật Thông Tin, cũng như các
                      điều khoản khác được Điều Khoản Sử Dụng này, Chính Sách
                      Bảo Mật Thông Tin viện dẫn đến, cùng với các bản sửa đổi,
                      bổ sung tùy từng thời điểm.
                    </li>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">2. Định Nghĩa:</p>
                  <div className="h-6" />
                  <span>
                    Trong phạm vi của Điều Khoản Sử Dụng này, các từ, cụm từ
                    viết hoa sẽ được hiểu như định nghĩa dưới đây, trừ khi ngữ
                    cảnh buộc phải hiểu khác đi.
                  </span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc space-y-1">
                      <li>
                        "Ứng Dụng" hoặc "Sản Phẩm": là trò chơi điện tử trên
                        mạng WK.
                      </li>
                      <li>
                        "Trang Chủ Của Ứng Dụng": là{" "}
                        <a href="https://whykids.edu.vn/">
                          <strong className="underline decoration-2">
                            https://whykids.edu.vn/
                          </strong>
                        </a>
                      </li>
                      <li>
                        "Quy Định Ứng Dụng": là các điều khoản, điều kiện được
                        quy định tại Điều Khoản Sử Dụng này và/hoặc các thông
                        báo, tin tức trong Ứng Dụng được WK thông báo trong Ứng
                        Dụng và/hoặc tại Trang Chủ Của Ứng Dụng.
                      </li>
                      <li>
                        "Dữ Liệu": là các nội dung/thông tin được tạo/sinh ra
                        trong quá trình Bạn tham gia, sử dụng Ứng Dụng được hệ
                        thống máy chủ (server) của Ứng Dụng ghi nhận lại.
                      </li>
                      <li>
                        "Thông Tin Cá Nhân": là các thông tin nhằm định danh một
                        cá nhân cụ thể như được ghi nhận tại Điều 3 của Điều
                        Khoản Sử Dụng này.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span className="font-bold">3. Tài khoản và đăng nhập</span>
                  <div className="h-6" />
                  <span>
                    Để sử dụng Trò Chơi, Bạn cần phải có tài khoản để đăng nhập
                    vào Trò Chơi. Việc tạo tài khoản và đăng nhập vào Trò Chơi
                    có thể thực hiện thông qua một trong các cách thức sau:
                  </span>
                  <div className="w-full flex justify-between ">
                    <div className="w-[48%] ">
                      <img
                        className="w-[100%]"
                        src="/term_policy/img1.png"
                        alt=""
                      />
                      <p className="text-center mt-5 font-bold">Hình 1</p>
                    </div>
                    <div className="w-[48%]">
                      <img
                        className="w-[100%]"
                        src="/term_policy/img2.png"
                        alt=""
                      />
                      <p className="text-center mt-5 font-bold">Hình 2</p>
                    </div>
                  </div>
                  <div className="h-6" />
                  <span>
                    Sau khi người chơi nhập email/số điện thoại vào trường dữ
                    liệu như <strong>hình 1</strong>, hệ thống nhận biết và gửi
                    OTP về email/số điện thoại. Sau đó người dùng sẽ tiếp tục
                    nhập tên, tuổi, giới tính cho trẻ như{" "}
                    <strong>hình 2</strong>, để vào màn hình chính của ứng dụng.
                  </span>
                  <div className="h-6" />
                  <div className="w-full flex justify-between ">
                    <div className="w-[48%] ">
                      <img
                        className="w-[100%]"
                        src="/term_policy/img4.png"
                        alt=""
                      />
                      <p className="text-center mt-5 font-bold">Hình 3</p>
                    </div>
                    <div className="w-[48%]">
                      <img
                        className="w-[100%]"
                        src="/term_policy/img3.png"
                        alt=""
                      />
                      <p className="text-center mt-5 font-bold">Hình 4</p>
                    </div>
                  </div>
                  <div className="h-6" />
                  <span>
                    Sau đó nếu có bổ sung thông tin thì người dùng có thể vào
                    phần cài đặt của ứng dụng để bổ sung hoặc cập nhật thông tin
                    của phụ huynh như <strong> hình 3</strong>. Hoặc có thể cập
                    nhật thông tin/xóa tài khoản của trẻ như{" "}
                    <strong>hình 4.</strong>
                  </span>
                  <div className="h-6" />
                  <span>
                    Các thông tin của tài khoản (tên, tuổi, email, số điện
                    thoại, …) được lưu trữ trong database của hệ thống và những
                    thông tin này sẽ được backup theo quy trình. 
                  </span>
                  <div className="h-6" />
                  <span>
                    Thông tin của người chơi hoàn toàn bảo mật và chỉ có Admin
                    mới có quyền kiểm tra. Các thông tin về hoạt động của tài
                    khoản trong trò chơi được hệ thống các server trò chơi lưu
                    trữ dưới dạng file log. Toàn bộ file log này được sao lưu
                    theo các bước trong quy trình.  
                  </span>
                  <div className="h-6" />
                  <span>
                    Doanh nghiệp cam kết bảo đảm bí mật thông tin người chơi,
                    chỉ sử dụng cho mục đích cung cấp dịch vụ và chăm sóc khách
                    hàng, không tiết lộ cho bên thứ ba, chỉ cung cấp cho Cơ quan
                    quản lý Nhà nước có thẩm quyền khi được yêu cầu. 
                  </span>
                  <div className="h-6" />
                  <span>
                    Trong tài khoản của người chơi luôn có mục cập nhật thông
                    tin để người chơi cập nhật lại thông tin tài khoản của mình.
                    Ngoài ra, trong quá trình phát hành dịch vụ, Công ty định kỳ
                    triển khai các đợt gọi điện, gửi email... cho khách hàng để
                    lấy thông tin cá nhân của khách hàng cập nhật vào hệ thống,
                    đảm bảo các thông tin luôn được bổ sung và chỉnh sửa kịp
                    thời cho phù hợp với thực tế. 
                  </span>
                  <div className="h-6" />
                  <span>
                    Công ty sử dụng phần mềm nhật ký hệ thống là hệ thống lưu
                    trữ thông tin về nhật ký các hoạt động của thành viên. Theo
                    đó, tất cả các thông tin, hành động của thành viên đều được
                    ghi nhận lại dưới dạng log-file và được lưu trữ theo ngày
                    nhằm mục đích hỗ trợ bộ phận tra cứu thông tin để xử lý các
                    yêu cầu khi cần thiết.  
                  </span>
                  <div className="h-6" />
                  <span className="font-bold">
                    4. Chính sách bảo mật và quyền riêng tư:
                  </span>
                  <br />
                  <span className="font-bold mt-1">4.1 Phân tải:</span>
                  <div className="h-6" />
                  <span className="">
                    Để đảm bảo hệ thống game hoạt động ổn định và tránh quá tải,
                    Công ty cổ phần Vì Sao sẽ sử dụng Google Cloud Load
                    Balancer. Dịch vụ này phân phối lưu lượng truy cập một cách
                    thông minh đến các máy chủ backend thay vì chia theo chức
                    năng.
                  </span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Phân tách chức năng: Mỗi máy chủ backend đảm nhận một
                        chức năng cụ thể như xử lý logic game, quản lý cơ sở dữ
                        liệu, hoặc phục vụ nội dung tĩnh.
                      </li>
                      <li>
                        Tăng khả năng chịu tải: Dễ dàng thêm máy chủ backend khi
                        lượng người chơi tăng đột biến.
                      </li>
                      <li>
                        Cải thiện trải nghiệm người dùng: Giảm lag và giật hình,
                        mang lại trải nghiệm chơi game mượt mà hơn.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span className="font-bold mt-1">
                    4.2 Phương án dự phòng:
                  </span>
                  <div className="h-6" />
                  <span className="">
                    Google Cloud cung cấp giải pháp dự phòng toàn diện để đảm
                    bảo dữ liệu và ứng dụng luôn sẵn sàng, ngay cả khi xảy ra sự
                    cố. Hạ tầng đám mây phân tán toàn cầu của Google Cloud mang
                    lại dịch vụ dự phòng mạnh mẽ và linh hoạt.
                  </span>
                  <div className="h-6" />
                  <p>Các dịch vụ dự phòng chính:</p>
                  <ul className="list-disc pl-10 mt-2">
                    <ul className="list-disc">
                      <li>
                        Google Cloud Storage: Tạo bản sao dữ liệu ở nhiều vùng
                        khác nhau, bảo vệ dữ liệu khỏi mất mát do sự cố tại một
                        vùng cụ thể.
                      </li>
                      <li>
                        Google Compute Engine: Cung cấp tính năng snapshot,
                        image và instance template để tạo bản sao và khôi phục
                        máy chủ ảo nhanh chóng.
                      </li>
                      <li>
                        Cải thiện trải nghiệm người dùng: Giảm lag và giật hình,
                        mang lại trải nghiệm chơi game mượt mà hơn.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <p>Quy trình dự phòng và khôi phục:</p>
                  <ul className="list-disc pl-10 mt-2">
                    <ul className="list-disc">
                      <li>
                        Tạo bản sao: Hệ thống tự động tạo bản sao dữ liệu và máy
                        chủ theo định kỳ hoặc khi có thay đổi.
                      </li>
                      <li>
                        Lưu trữ: Bản sao được lưu tại các vùng khác nhau để đảm
                        bảo tính sẵn sàng.
                      </li>
                      <li>
                        Khôi phục: Hệ thống có thể tự động hoặc thủ công khôi
                        phục dữ liệu và máy chủ từ bản sao gần nhất khi xảy ra
                        sự cố.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span className="font-bold mt-1">
                    4.3. Giải pháp khi xử lý sự cố:
                  </span>
                  <div className="h-6" />
                  <span>
                    Khi xảy ra sự cố về mạng, nhân viên hỗ trợ sẽ ngay lập tức
                    kiểm tra kết nối và có kỹ thuật viên đến data center để xử
                    lý. Nếu sự cố liên quan đến server hoặc phần cứng, hệ thống
                    sẽ tự động chuyển sang server dự phòng.
                  </span>
                  <div className="h-6" />
                  <span>Quy trình xử lý sự cố:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Nhân viên trực ca đăng nhập và kiểm tra bằng dòng lệnh
                        (như telnet Web, Mail) qua các cổng 80, 110.
                      </li>
                      <li>
                        Sử dụng ping để kiểm tra địa chỉ IP qua cổng 21, 210 và
                        tracert để theo dõi quá trình vận chuyển gói tin.
                      </li>
                      <li>Sử dụng remote desktop để quản trị các máy chủ.</li>
                      <li>
                        Nếu sự cố không được xử lý trong vòng 15 phút, nhân viên
                        sẽ báo cáo cho quản trị cấp cao hơn để tìm biện pháp
                        khắc phục kịp thời.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span className="font-bold mt-1">4.4. Bảo trì hệ thống</span>
                  <div className="h-6" />
                  <span>
                    Để đảm bảo tính toàn vẹn và khả năng phục hồi dữ liệu, hệ
                    thống sử dụng dịch vụ sao lưu của Google Cloud Platform.
                  </span>
                  <div className="h-6" />
                  <span>Lịch trình sao lưu:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Hàng ngày: Tạo snapshot cho các máy ảo và sao lưu dữ
                        liệu ứng dụng trên Google App Engine.
                      </li>
                      <li>
                        Hàng tuần: Sao lưu toàn bộ dữ liệu vào Google Cloud
                        Storage.
                      </li>
                      <li>
                        Hàng tháng: Lưu trữ các bản sao lưu lâu dài trên Google
                        Cloud Storage hoặc phương tiện lưu trữ khác.
                      </li>
                      <li>
                        Nếu sự cố không được xử lý trong vòng 15 phút, nhân viên
                        sẽ báo cáo cho quản trị cấp cao hơn để tìm biện pháp
                        khắc phục kịp thời.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Cơ chế sao lưu và khôi phục:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Sao lưu tăng dần: Chỉ sao lưu dữ liệu thay đổi so với
                        bản sao lưu trước để tiết kiệm thời gian và dung lượng.
                      </li>
                      <li>
                        Khôi phục nhanh: Khôi phục dữ liệu và máy ảo từ các bản
                        sao lưu một cách nhanh chóng.
                      </li>
                      <li>
                        Tự động hóa: Sử dụng công cụ của Google Cloud để tự động
                        hóa quá trình sao lưu và khôi phục.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Bảo mật và lưu trữ:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Mã hóa dữ liệu: Tất cả dữ liệu được mã hóa khi lưu trữ
                        và truyền tải.
                      </li>
                      <li>
                        Kiểm soát truy cập: Chỉ những người có quyền mới được
                        truy cập vào dữ liệu.
                      </li>
                      <li>
                        Lưu trữ đa vùng: Dữ liệu được lưu trữ ở nhiều vùng khác
                        nhau để đảm bảo tính sẵn sàng.
                      </li>
                    </ul>
                  </ul>
                  {/* 4.5 sao luu */}
                  <div className="h-6" />
                  <span className="font-bold mt-1">4.5. Sao lưu dữ liệu</span>
                  <div className="h-6" />
                  <span>
                    Kênh chăm sóc khách hàng: Email, điện thoại, diễn đàn.
                  </span>
                  <div className="h-6" />
                  <span>Nội dung tiếp nhận và xử lý:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Ghi nhận sự cố máy chủ: Tiếp nhận thông tin và chuyển
                        cho bộ phận kỹ thuật xử lý.
                      </li>
                      <li>
                        Giải quyết khiếu nại: Liên quan đến mất vật phẩm ảo và
                        thanh toán. Khách hàng cần cung cấp tài khoản và thông
                        tin cá nhân để bộ phận chăm sóc khách hàng chuyển đến kỹ
                        thuật và kế toán.
                      </li>
                      <li>
                        Giải quyết tranh chấp tài khoản: Khách hàng cung cấp
                        chính xác thông tin tài khoản và cá nhân. Thông tin
                        chính xác trong đăng ký là cơ sở giải quyết tranh chấp.
                      </li>
                      <li>
                        Hướng dẫn chơi game: Cung cấp thông tin liên quan đến
                        cách chơi và các sự kiện sắp diễn ra.
                      </li>
                      <li>
                        Thông báo bảo trì: Thông báo về các sự kiện và bảo trì
                        hệ thống.
                      </li>
                      <li>
                        Hướng dẫn bảo mật: Hướng dẫn về cách bảo mật tài khoản,
                        đổi mật khẩu và cập nhật thông tin.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>
                    Công ty cam kết giải quyết mọi khiếu nại trong vòng 24 giờ.
                    Với các trường hợp phức tạp, sẽ thông báo cụ thể thời gian
                    giải quyết cho khách hàng.
                  </span>

                  {/* 4.6 Cung cấp các kênh chăm sóc, hỗ trợ khách hà */}
                  <div className="h-6" />
                  <span className="font-bold mt-1">
                    4.6. Cung cấp các kênh chăm sóc, hỗ trợ khách hàng
                  </span>
                  <div className="h-6" />
                  <span>Kênh chăm sóc khách hàng.</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>Email: hotline@whycorp.vn</li>
                      <li>Điện thoại</li>
                      <li>Diễn đàn</li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Nội dung tiếp nhận và xử lý:</span>
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Ghi nhận sự cố máy chủ: Tiếp nhận thông tin và chuyển
                        cho bộ phận kỹ thuật.
                      </li>
                      <li>
                        Giải quyết khiếu nại: Liên quan đến mất vật phẩm ảo và
                        thanh toán; yêu cầu khách hàng cung cấp tài khoản và
                        thông tin cá nhân để xử lý.
                      </li>
                      <li>
                        Giải quyết tranh chấp tài khoản: Khách hàng cung cấp
                        thông tin tài khoản và cá nhân; thông tin chính xác
                        trong đăng ký là cơ sở giải quyết.
                      </li>
                      <li>
                        Hướng dẫn chơi game: Cung cấp thông tin cách thức chơi
                        và các sự kiện liên quan
                      </li>
                      <li>
                        Thông báo bảo trì: Cập nhật về các sự kiện sắp diễn ra
                        và thông báo bảo trì hệ thống.
                      </li>
                      <li>
                        Hướng dẫn bảo mật tài khoản: Hướng dẫn đổi mật khẩu và
                        cập nhật thông tin tài khoản.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>
                    Công ty cam kết giải quyết mọi khiếu nại trong vòng 24 giờ.
                    Đối với các trường hợp phức tạp, sẽ thông báo thời gian giải
                    quyết cụ thể cho khách hàng
                  </span>
                  {/* 4.7. Cung cấp chính sách, công cụ để khách hàng tự bảo vệ quyền lợi của mình*/}
                  <div className="h-6" />
                  <span className="font-bold mt-1">
                    4.7. Cung cấp chính sách, công cụ để khách hàng tự bảo vệ
                    quyền lợi của mình
                  </span>
                  <div className="h-6" />
                  <span>Hệ thống tài khoản sử dụng dịch vụ:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Khách hàng phải đăng ký tài khoản để sử dụng dịch vụ,
                        tuân thủ quy định về tên tài khoản và mật khẩu.
                      </li>
                      <li>
                        Thông tin họ và tên thật được ưu tiên trong tranh chấp
                        tài khoản, và người giám hộ hợp pháp phải chịu trách
                        nhiệm cho thông tin của trẻ dưới 14 tuổi.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Hệ thống nhật ký giao dịch:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Hệ thống lưu trữ và cho phép khách hàng tự kiểm tra giao
                        dịch trên internet, bao gồm các hoạt động nạp tiền, tiêu
                        tiền, chuyển khoản với ngày giờ và giá trị giao dịch.
                      </li>
                      <li>
                        Khách hàng có thể tra cứu lịch sử giao dịch 30 ngày gần
                        nhất. Để tra cứu trên 30 ngày, liên hệ qua số điện thoại
                        hỗ trợ.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Hệ thống đóng băng tài khoản:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Khách hàng có thể đóng băng thông tin tài khoản, yêu cầu
                        mã OTP cho mỗi thay đổi để bảo mật.
                      </li>
                      <li>
                        Có thể đóng băng giá trị tài khoản, ngăn không cho sử
                        dụng cho đến khi mở băng, và cũng yêu cầu mã OTP để xác
                        thực.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>
                    Hệ thống nhắc nhở khách hàng về an toàn thông tin:
                  </span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Hệ thống quản lý tài khoản thường xuyên cảnh báo và nhắc
                        nhở khách hàng về việc bảo mật tài khoản.
                      </li>
                    </ul>
                  </ul>
                  {/* 4.8. Kế hoạch đảm bảo bí mật, an toàn thông tin cá nhân người chơi  */}
                  <div className="h-6" />
                  <span className="font-bold mt-1">
                    4.8. Kế hoạch đảm bảo bí mật, an toàn thông tin cá nhân
                    người chơi
                  </span>
                  <div className="h-6" />
                  <span>
                    Kế hoạch đảm bảo bí mật và an toàn thông tin cá nhân người
                    chơi:
                  </span>
                  <div className="h-6" />
                  <span>Thông tin yêu cầu:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Người chơi phải cung cấp các thông tin như: Họ và tên,
                        Ngày sinh, Giới tính, Số chứng minh nhân dân/căn cước,
                        Ngày và nơi cấp, Số điện thoại, Email, Tên đăng ký, Mật
                        khẩu.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Lưu trữ và sao lưu:</span>
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Tất cả thông tin được lưu tại Database và được sao lưu
                        theo quy trình bảo mật.
                      </li>
                      <li>
                        Thông tin nhân vật trong game (như thời gian đăng nhập,
                        cấp độ, vật dụng) được lưu dưới dạng logfile và cũng
                        được sao lưu.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Bảo mật thông tin:</span>
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Dữ liệu được lưu trữ trên máy chủ với bảo mật nhiều lớp,
                        chỉ cho phép quản trị viên cao cấp truy cập.
                      </li>
                      <li>
                        Máy chủ kết nối với mạng nội bộ, ngăn không cho truy cập
                        từ bên ngoài.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Quy trình truy cập:</span>
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Quản trị viên phải sử dụng mật khẩu hệ điều hành và
                        thiết bị token để truy cập dữ liệu; nếu không đủ điều
                        kiện, không thể truy xuất thông tin.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Mã hóa dữ liệu:</span>
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Các dữ liệu nhạy cảm như mật khẩu và mã thẻ được mã hóa
                        một chiều trước khi lưu vào cơ sở dữ liệu, đảm bảo không
                        ai, kể cả quản trị viên, có thể đọc thông tin.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Tường lửa:</span>
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Hệ thống tường lửa được thiết lập để ngăn chặn truy cập
                        trái phép vào máy chủ.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Cam kết bảo mật:</span>
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Công ty cam kết bảo mật thông tin và chỉ sử dụng cho mục
                        đích cung cấp dịch vụ và chăm sóc khách hàng.
                      </li>
                      <li>
                        Không cung cấp thông tin cá nhân cho bên thứ ba, trừ khi
                        yêu cầu từ cơ quan quản lý nhà nước có thẩm quyền.
                      </li>
                    </ul>
                  </ul>
                  {/* 4.9. Kế hoạch đảm bảo bí mật, an toàn thông tin cá nhân người chơi  */}
                  <div className="h-6" />
                  <span className="font-bold mt-1">
                    4.9. Đảm bảo quyền lợi của người chơi trong trường hợp ngừng
                    dịch vụ
                  </span>
                  <div className="h-6" />
                  <span>
                    Đảm bảo quyền lợi của người chơi trong trường hợp ngừng dịch
                    vụ: chơi:
                  </span>
                  <div className="h-6" />
                  <span>Trường hợp dừng một số dịch vụ trò chơi:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Thông báo:
                        <ul className="pl-10">
                          <li>
                            - Công ty sẽ thông báo cho khách hàng tối thiểu 30
                            ngày trước khi dừng dịch vụ, nêu rõ lý do và thời
                            gian ngừng dịch vụ, giúp khách hàng chủ động trong
                            việc sử dụng dịch vụ và quản lý nhân vật
                          </li>
                        </ul>
                      </li>
                      <li>
                        Đền bù:
                        <ul className="pl-10">
                          <li>
                            - Khách hàng sẽ được đền bù bằng gói ưu đãi tương
                            đương giá trị hiện có trong tài khoản dịch vụ bị
                            đóng cửa.
                          </li>
                          <li>
                            - Ưu tiên tặng quà cho khách hàng khi sử dụng dịch
                            vụ mới cùng loại (nếu có).
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span>Trường hợp dừng tất cả các dịch vụ trò chơi:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Thông báo:
                        <ul className="pl-10">
                          <li>
                            - Công ty sẽ thông báo cho khách hàng tối thiểu 90
                            ngày trước khi dừng tất cả dịch vụ, nêu rõ lý do và
                            thời gian ngừng dịch vụ, giúp khách hàng chủ động
                            quản lý tài khoản.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Đền bù:
                        <ul className="pl-10">
                          <li>
                            - Hoàn trả tiền qua tài khoản ngân hàng của khách
                            hàng, tương đương giá trị hiện có trong tài khoản
                            dịch vụ bị đóng cửa.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Xóa tài khoản:
                        <ul className="pl-10">
                          <li>
                            - Sau khi đền bù, công ty sẽ thông báo việc xóa tài
                            khoản qua email và số điện thoại đã đăng ký.
                          </li>
                          <li>
                            - Xóa tài khoản sẽ diễn ra trong 48 giờ sau khi hoàn
                            tất quá trình đền bù.
                          </li>
                          <li>
                            Thông tin cá nhân của khách hàng sẽ được xóa khỏi hệ
                            thống trong vòng 24 giờ để đảm bảo an toàn dữ liệu.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Báo cáo pháp lý:
                        <ul className="pl-10">
                          <li>
                            - Trước khi ngừng dịch vụ, công ty sẽ báo cáo với
                            Cục Phát thanh truyền hình và thông tin điện tử theo
                            quy định pháp luật.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </ul>
                  {/* 4.10 Quy định sử dụng dịch vụ */}
                  <div className="h-6" />
                  <span className="font-bold mt-1">
                    4.10 Quy định sử dụng dịch vụ
                  </span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10 space-y-2">
                    <li>
                      Tài khoản sử dụng: Người dùng nên kích hoạt tài khoản qua
                      email xác nhận. Chỉ những tài khoản đã kích hoạt mới được
                      chấp nhận khi có rủi ro. Các thông tin như thời gian nạp
                      tiền và giấy tờ tùy thân sẽ được dùng để giải quyết khiếu
                      nại.
                    </li>
                    <li>
                      Thông tin không chính xác: Nếu người dùng cung cấp thông
                      tin không đúng hoặc không trung thực, nhà phát hành có
                      quyền tạm đình chỉ hoặc chấm dứt tài khoản mà không chịu
                      trách nhiệm.
                    </li>
                    <li>
                      Mật khẩu tài khoản: Người dùng phải bảo quản mật khẩu tài
                      khoản. Nếu mật khẩu bị lộ, nhà phát hành không chịu trách
                      nhiệm cho bất kỳ thiệt hại nào. Mật khẩu chỉ có thể được
                      lấy lại nếu tài khoản đã được kích hoạt.
                    </li>
                    <li>
                      Cấm can thiệp: Nghiêm cấm sử dụng bất kỳ chương trình hay
                      công cụ nào để hack, cheat, hoặc can thiệp vào ứng dụng.
                      Vi phạm sẽ dẫn đến khóa tài khoản vĩnh viễn và có thể bị
                      xử lý theo pháp luật.
                    </li>
                    <li>
                      Cấm phát tán thông tin xấu: Không được phát tán hay cổ vũ
                      các hoạt động phá hoại dữ liệu hoặc hệ thống máy chủ.
                    </li>
                    <li>
                      Báo lỗi: Người dùng cần thông báo cho nhà phát hành khi
                      phát hiện lỗi trong ứng dụng.
                    </li>
                    <li>
                      Cấm truy cập trái phép: Cấm mọi hành vi đăng nhập trái
                      phép và gây thiệt hại cho hệ thống. Vi phạm sẽ bị khóa tài
                      khoản vĩnh viễn và có thể bị truy tố.
                    </li>
                    <li>
                      Cấm mua bán vật phẩm: Không chấp nhận giao dịch mua bán
                      vật phẩm hoặc tài khoản bằng tiền thật. Vi phạm sẽ không
                      được khôi phục tài sản và tài khoản có thể bị khóa.
                    </li>
                    <li>
                      Hành vi giao tiếp: Cấm quấy rối, chửi bới hoặc có hành vi
                      thiếu văn hóa đối với người khác. Xúc phạm hoặc nhạo báng
                      người khác cũng bị cấm.
                    </li>
                    <li>
                      Mạo nhận: Cấm mạo nhận người khác trong ứng dụng. Vi phạm
                      sẽ bị xử lý hoặc khóa tài khoản.
                    </li>
                    <li>
                      Trách nhiệm: Nhà phát hành không chịu trách nhiệm cho bất
                      kỳ vấn đề nào liên quan đến hệ thống khi người dùng cài
                      đặt và sử dụng ứng dụng.
                    </li>
                    <li>
                      Vi phạm sử dụng cheats/hacks: Nhà phát hành có quyền sử
                      dụng thông tin tài khoản để báo cáo các vi phạm như cheats
                      hay hacks cho cơ quan chức năng.
                    </li>
                    <li>
                      Bất khả kháng: Người dùng phải chấp nhận thiệt hại do các
                      sự kiện bất khả kháng như chập điện hay thiên tai theo quy
                      định pháp luật.
                    </li>
                    <li>
                      Quyền sửa đổi thông tin: Nhà phát hành có quyền xóa, sửa
                      đổi thông tin tài khoản nếu vi phạm quy định mà không cần
                      sự đồng ý của người dùng.
                    </li>
                    <li>
                      Cấm tuyên truyền chính trị: Nghiêm cấm mọi hành vi tuyên
                      truyền chống phá chính quyền và xuyên tạc các chính sách
                      nhà nước. Vi phạm sẽ bị xóa tài khoản và thông tin có thể
                      được cung cấp cho cơ quan chức năng.
                    </li>
                    <li>
                      Cấm bàn luận chính trị: Không được bàn luận về các vấn đề
                      chính trị trong ứng dụng.
                    </li>
                    <li>
                      Bảo vệ uy tín: Cấm các hành vi làm tổn hại đến uy tín sản
                      phẩm và công ty phát hành. Vi phạm sẽ dẫn đến mất quyền
                      lợi và có thể bị xử lý theo pháp luật.
                    </li>
                    <li>
                      Giao dịch trong game: Nhà phát hành không chịu trách nhiệm
                      về bất kỳ giao dịch, mất mát hay thiệt hại nào liên quan
                      đến việc mua bán và trao đổi trong game.
                    </li>
                    <li>
                      Bảo mật thông tin cá nhân: Tất cả thông tin cá nhân của
                      khách hàng được bảo mật và không tiết lộ cho bên thứ ba,
                      ngoại trừ khi có yêu cầu từ cơ quan chức năng.
                    </li>
                    <li>
                      Cấm quảng bá sản phẩm khác: Nghiêm cấm việc quảng bá bất
                      kỳ sản phẩm nào khác trên game và diễn đàn mà không có sự
                      đồng ý bằng văn bản.
                    </li>
                    <li>
                      Cấm cá cược và cờ bạc: Không được tổ chức cá cược, cờ bạc
                      hoặc thỏa thuận liên quan đến tiền, hiện kim, hiện vật. Vi
                      phạm sẽ bị khóa tài khoản vĩnh viễn và có thể bị truy tố
                      theo pháp luật.
                    </li>
                    <li>
                      Thay đổi thỏa thuận: Nhà phát hành có quyền thay đổi, bổ
                      sung hoặc sửa chữa thỏa thuận và sẽ công bố trên website
                      cũng như diễn đàn.
                    </li>
                    <li>
                      Xóa tài khoản không hoạt động: Tài khoản sẽ bị xóa nếu
                      không đăng nhập vào game trong vòng một năm tính từ lần sử
                      dụng cuối.
                    </li>
                    <li>
                      Giải quyết khiếu nại: Các khiếu kiện liên quan đến tài
                      khoản hay nhân vật bị khóa sẽ chỉ được tiếp nhận và giải
                      quyết trong vòng 14 ngày kể từ ngày bị khóa.
                    </li>
                  </ul>

                  <p className="font-bold">5. Quyền sở hữu trí tuệ</p>
                  <div className="h-6" />
                  <span className="">Nội dung và bản quyền:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Tất cả nội dung trong ứng dụng bao gồm văn bản, hình
                        ảnh, âm thanh, video, và các phần mềm liên quan đều
                        thuộc quyền sở hữu trí tuệ của Công ty Cổ phần Vì Sao.
                        Mọi hành vi sao chép, phân phối hoặc sử dụng nội dung mà
                        không có sự cho phép sẽ bị coi là vi phạm bản quyền.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span className="">Thương hiệu:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Các thương hiệu và logo xuất hiện trong ứng dụng WhyKids
                        là tài sản của Công ty Cổ phần Vì Sao. Người dùng không
                        được phép sử dụng bất kỳ thương hiệu nào mà không có sự
                        đồng ý của Công ty.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span className="">
                    Xin lưu ý rằng WK giữ quyền sửa đổi và/hoặc bổ sung bất kỳ
                    điều khoản nào của Chính sách Bảo mật này vào bất kỳ thời
                    điểm nào mà không cần phải thông báo trước. Những sự thay
                    đổi và/hoặc sửa đổi như vậy sẽ có hiệu lực ngay khi đăng lên
                    website:{" "}
                    <a href="https://whykids.edu.vn/">
                      <strong> https://whykids.edu.vn.</strong>
                    </a>{" "}
                    Nếu Bạn tiếp tục sử dụng website{" "}
                    <a href="https://whykids.edu.vn/">
                      <strong> https://whykids.edu.vn.</strong>
                    </a>{" "}
                    sau khi các thay đổi và/hoặc sửa đổi được đăng lên, Bạn đã
                    chấp nhận và đồng ý với các thay đổi và/hoặc sửa đổi đó.
                    Chúng tôi cũng sẽ giữ các phiên bản trước của Chính sách Bảo
                    mật này trong hồ sơ lưu trữ để bạn tiện xem xét. Mọi thắc
                    mắc hoặc khiếu nại liên quan đến việc sử dụng ứng dụng có
                    thể liên hệ trực tiếp với chúng tôi qua email:{" "}
                    <strong>hotline@whycorp.vn</strong> hoặc qua website:{" "}
                    <strong>whykids.edu.vn.</strong>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default PolicyVNPage;
