import axiosConfigSchool from "./axiosConfigSchool";

export const login = async (username: string, password: string) => {
    try {
      const response = await axiosConfigSchool({
        method:"POST",
        url:"/v2/auth/login",
        data:{
            username:username,
            password: password
        }
      });
      return response.data; 
    } catch (error) {
      return "Fail";
    }
  };

  export const getDataTest = async () => {
    try {
      const response = await axiosConfigSchool({
        method:"GET",
        url:"/v2/study-material/island",
      });
      return response.data; 
    } catch (error) {
      return "Fail";
    }
  };


