import axiosConfigUser from ".././axiosConfigUser";

export const loginUser = async (username: string) => {
    try {
      const response = await axiosConfigUser({
        method:"POST",
        url:"/auth/otp/login",
        data:{
            account:username,
        }
      });
      return response.data; 
    } catch (error) {
      return 'Fail';
    }
  };

  export const verifyOTPUser = async (session_id: string, otp:String) => {
    try {
      const response = await axiosConfigUser({
        method:"POST",
        url:"/auth/otp/verify",
        data:{
            session_id:session_id,
            otp:otp,
        }
      });
      return response.data; 
    } catch (error) {
      return error;
    }
  };

  export const getAccountInformation = async () => {
    try {
      const response = await axiosConfigUser({
        method:"GET",
        url:"/account/",
      });
      return response.data; 
    } catch (error) {
      return "Fail";
    }
  };


