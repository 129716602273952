import React from "react";
import { Interface } from "readline";

interface CreditProps {
  handleCancel: () => void;
}

function CreditModal({ handleCancel }: CreditProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-[#65AFFF] relative rounded-[30px]">
        <div className="w-auto flex-col p-10 ">
          <p className="text-white font-[800] text-[48px]">
            Thanh toán thể tín dụng
          </p>
          <div className="flex w-full justify-center items-center my-5 gap-12">
            <img
              className="h-[30px] w-[98px] object-contain"
              src="/logo/creditCardLogo/visa.png"
              alt="visa"
            />
            <img
              className="h-[53px] w-[74px] object-contain"
              src="/logo/creditCardLogo/Mastercard.png"
              alt="Mastercard"
            />
            <img
              className="h-[40px] w-[54px] object-contain"
              src="/logo/creditCardLogo/JCB.png"
              alt="JCB"
            />
          </div>
          <p className="text-[18px] font-[600] text-white">Số thẻ:</p>
          <input
            className="w-full h-12 bg-[#9DCBFC] rounded-full mt-1 sm:mt-3 px-4  text-[23px] text-[#142548] font-semibold outline-none"
            placeholder="0000-0000-0000-0000"
          ></input>
          <div className="flex mt-5 gap-5">
            <div>
              <p className="text-[18px] font-[600] text-white">
                Tháng/ năm hết hạn:
              </p>
              <input
                className="w-full h-12 bg-[#9DCBFC] rounded-full mt-1 sm:mt-3 px-4  text-[23px] text-[#142548] font-semibold outline-none"
                placeholder="00/0000"
              ></input>
            </div>
            <div>
              <p className="text-[18px] font-[600] text-white">CVV / CSC:</p>
              <input
                className="w-full h-12 bg-[#9DCBFC] rounded-full mt-1 sm:mt-3 px-4  text-[23px] text-[#142548] font-semibold outline-none"
                placeholder="00/0000"
              ></input>
            </div>
          </div>
          <div className="flex justify-center mt-10">
            <button className="bg-[#142548] font-bold py-1 px-10 rounded-[33px] text-white">
              <p className="text-[20px]"> Thanh toán</p>
            </button>
          </div>
        </div>
        <button
          onClick={handleCancel}
          className="absolute -top-5 -right-[50px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-white hover:text-gray-400 font-bold"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
export default CreditModal;
