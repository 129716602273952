import { Carousel } from "antd";
import React from "react";

const characterList = [
  "character_gif/ca_voi.gif",
  "character_gif/cu.gif",
  "character_gif/lung.gif",
  "character_gif/nghe.gif",
  "character_gif/TK.gif",
];

interface CharacterCardProps {
  content: string;
  reverseRow: boolean;
}

function CharacterCarousel({
  content,
  reverseRow = false,
}: CharacterCardProps) {
  return (
    <div
      className={`w-full px-20 py-16 pv:max-md:px-5 pv:max-md:py-10 justify-between flex ${
        reverseRow ? "flex-row" : "flex-row-reverse"
      } pv:max-md:flex-col-reverse pv:max-md:items-center`}
    >
      <div className="md:max-xl:w-[60%] w-[40%] md:max-xl:h-[40vh] dvmax:max-dh:h-[60vh] pv:max-md:w-full place-content-end">
        <Carousel
          autoplay
          speed={100}
          lazyLoad="progressive"
          effect="fade"
          fade={true}
          dots={false}
          className="w-full min-h-fit"
        >
          {characterList.map((imageSrc, index) => (
            <img
              src={imageSrc}
              alt={`Slide ${index + 1}`}
              className="w-full md:max-xl:h-[40vh] dvmax:max-dh:h-[60vh] object-cover"
            />
          ))}
        </Carousel>
      </div>
      <div className="flex flex-col justify-end md:max-xl:w-[40%] w-[60%] pv:max-md:w-full pv:max-md:text-center">
        <p className="uppercase font-[800] dvmax:max-dh:text-[105px] tracking-wide text-[#142548] sm:max-md:text-3xl pv:max-md:text-[26px] md:max-lg:text-[40px] lg:max-dh:text-[60px]">
          Hệ Thống <br />
          nhân vật đặc biệt
        </p>
        <p className="font-[500] text-[#142548] text-[20px] dvmax:max-dh:text-[30px] sm:max-md:text-xl md:max-lg:text-[14px]">
          {content}
        </p>
      </div>
    </div>
  );
}

export default CharacterCarousel;
