import React from 'react';
import { useReferral } from './ReferralContext';

const ReferralTable: React.FC = () => {
  const { referrals } = useReferral();

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(amount);
  };


  return (
    <div>
        <h1 className='font-bold text-[30px] m-8' >ADMIN PAGE</h1>
        <div style={{ display: 'flex', justifyContent: 'center',  minHeight: '100vh' }}>
      <div>
        <h1 className='font-bold text-[30px]' style={{ textAlign: 'center' }}>Thông tin Referrer</h1>
        <table className='border-1' border={1} style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'center' }}>
          <thead>
            <tr>
              <th style={{ padding: '10px' }}>Tên</th>
              <th style={{ padding: '10px' }}>Referral Code</th>
              <th style={{ padding: '10px' }}>Total Amount</th>
              <th style={{ padding: '10px' }}>Lượt dùng Referral Code</th>
              <th style={{ padding: '10px' }}>Link Download App Store</th>
              <th style={{ padding: '10px' }}>Lượt tải App Store</th>
              <th style={{ padding: '10px' }}>Link Download Google Play</th>
              <th style={{ padding: '10px' }}>Lượt tải Google Play</th>
            </tr>
          </thead>
          <tbody>
            {referrals.map((referral, index) => (
              <tr key={index}>
                <td style={{ padding: '10px' }}>{referral.name}</td>
                <td style={{ padding: '10px' }}>{referral.referralCode}</td>
                <td style={{ padding: '10px' }}>{formatCurrency(referral.total)}</td>
                <td style={{ padding: '10px' }}>{referral.usageCount}</td>
                <td style={{ padding: '10px' }}>
                  <a target="_blank" rel="noopener noreferrer">
                    
                    <input type="text" placeholder={referral.appStoreLink}/>
                  </a>
                </td>
                <td style={{ padding: '10px' }}> 
                    <input type="text" placeholder='10'/>
                </td>
                <td style={{ padding: '10px' }}>
                  <a  target="_blank" rel="noopener noreferrer">
                  <input type="text" placeholder={referral.googlePlayLink}/>
                  </a>
                </td>
                <td style={{ padding: '10px' }}><input type="text" placeholder='9'/></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
    
  );
};

export default ReferralTable;
