import React from "react";
import { Carousel } from "antd";

const imageList = [
  "img_slider/img1.png",
  "img_slider/img2.png",
  "img_slider/img3.png",
  "img_slider/img4.png",
  "img_slider/img5.png",
  "img_slider/img6.png",
  "img_slider/img7.jpg",
  "img_slider/img8.jpg",
];
interface BodyCarouselProps {
  reverseRow: boolean;
}
function BodyCarousel({ reverseRow = false }: BodyCarouselProps) {
  return (
    <div
      className={`w-full px-20 py-16 pv:max-md:px-5 pv:max-md:py-10 justify-between flex ${
        reverseRow ? "flex-row" : "flex-row-reverse"
      } pv:max-md:flex-col-reverse pv:max-md:items-center`}
    >
      <div className="w-[53%] md:max-xl:h-[30vh] dvmax:max-dh:h-[60vh] pv:max-md:w-full place-content-end">
        <Carousel
          autoplay
          speed={100}
          lazyLoad="progressive"
          effect="fade"
          fade={true}
          dots={false}
          className="w-full h-full"
        >
          {imageList.map((imageSrc, index) => (
            <img
              src={imageSrc}
              alt={`Slide ${index + 1}`}
              className="w-full md:max-xl:h-[30vh] dvmax:max-dh:h-[60vh] object-cover rounded-[30px]"
            />
          ))}
        </Carousel>
      </div>
      <div className="flex flex-col justify-end w-[45%] pv:max-md:w-full pv:max-md:text-center">
        <p className="uppercase font-[800] dvmax:max-dh:text-[105px] tracking-wide text-[#142548] sm:max-md:text-3xl pv:max-md:text-[26px] md:max-lg:text-[40px] lg:max-dh:text-[60px]">
          {/* {title} */}
          Thuần Việt
        </p>
        <p className="font-[500] text-[#142548] text-[20px] dvmax:max-dh:text-[30px] sm:max-md:text-xl md:max-lg:text-[14px]">
          Whykids giúp trẻ tập trung phát triển ngôn ngữ tiếng Việt, hiểu biết
          sâu sắc về văn hóa Việt Nam, cùng với hình ảnh đồ họa đậm chất Việt,
          tạo nên một trải nghiệm học tập thuần Việt độc đáo.
        </p>
      </div>
    </div>
  );
}

export default BodyCarousel;
