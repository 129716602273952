import { useRef, useState } from "react";

import Footer from "../../components/Common/footer";
import Header from "../../components/Common/header/header";

import feature_01 from "../../assets/Library/feature-01.png";
import feature_02 from "../../assets/Library/feature-02.png";
import feature_03 from "../../assets/Library/feature-03.png";
import feature_04 from "../../assets/Library/feature-04.png";
import feature_05 from "../../assets/Library/feature-05.png";
import feature_06 from "../../assets/Library/feature-06.png";

import value_01 from "../../assets/Library/value-01.png";
import value_02 from "../../assets/Library/value-02.png";
import value_03 from "../../assets/Library/value-03.png";
import value_04 from "../../assets/Library/value-04.png";

import back from "../../assets/Library/back.png";
import next from "../../assets/Library/next.png";

import signIn from "../../assets/Library/sign-in.png";

import FeatureCard from "./FeatureCard";
import { useNavigate } from "react-router-dom";
import { getDataTest, login } from "../../services/authSchool.service";
import { GameProvider, useGameContext } from "../../contexts/gameContext";
import ValueCard from "./ValueCard";
import FlipCard from "./FlipCard";

const LearningLibraryIntroductionPage = () => {
  const [currentValue, setCurrentValue] = useState<number>(0);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<string | null>(null);

  const navigate = useNavigate();

  const targetRef = useRef<HTMLDivElement>(null);

  // Function to scroll to the target element
  const scrollToElement = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading("Đang trong quá trình đăng nhập...");
    if (isLoading == null) {
      const data = await login(email, password);
      console.log(data);

      if (data != "Fail") {
        setIsLoading(null);

        sessionStorage.setItem("accessTokenSchool", data.token.accessToken);
        sessionStorage.setItem("refreshTokenSchool", data.token.refreshToken);

        console.log(sessionStorage.getItem("accessTokenSchool"));

        navigate("/library");
      } else {
        setIsLoading(null);
        setError("Đăng nhập thất bại");
      }
    }
  };

  const featureCards = [
    {
      imageURL: feature_01,
      title: "Phát Triển Toàn Diện",
      color: "#FFBC42",
      content:
        "Whykids mang đến hệ thống video và hoạt động tương tác độc đáo, giúp trẻ vừa học vừa chơi, phát triển toàn diện từ tư duy, kỹ năng đến kiến thức một cách tự nhiên và hứng thú.",
    },
    {
      imageURL: feature_02,
      title: "Văn Hóa Việt Nam",
      color: "#65AFFF",
      content:
        "Nội dung được thiết kế riêng cho trẻ em Việt, dựa trên chương trình mầm non chuẩn Bộ Giáo dục, vừa gần gũi, vừa đậm đà bản sắc văn hóa dân tộc.",
    },
    {
      imageURL: feature_03,
      title: "Chất Lượng Đảm Bảo",
      color: "#9FD356",
      content:
        "Mỗi video và bài học đều được sản xuất kỹ lưỡng, đáp ứng tiêu chuẩn giáo dục cao nhất, an toàn và bổ ích cho trẻ ở mọi độ tuổi.",
    },
    {
      imageURL: feature_04,
      title: "Hệ Thống Hóa Dễ Dàng",
      color: "#007DA9",
      content:
        "Mọi nội dung được sắp xếp logic, khoa học, dễ dàng tra cứu và sử dụng, giúp giáo viên và phụ huynh tiết kiệm thời gian nhưng vẫn tối ưu hiệu quả.",
    },
    {
      imageURL: feature_05,
      title: "Cập Nhật Liên Tục",
      color: "#F56177",
      content:
        "Bộ học liệu không ngừng được làm mới với nội dung sáng tạo, đảm bảo luôn mang lại trải nghiệm học tập thú vị và bắt kịp xu hướng giáo dục hiện đại.",
    },
    {
      imageURL: feature_06,
      title: "Tương Thích Đa Thiết Bị",
      color: "#142548",
      content:
        "Whykids tương thích với mọi thiết bị, từ điện thoại, máy tính bảng đến máy tính, giúp người dùng dễ dàng truy cập và sử dụng mọi lúc, mọi nơi.",
    },
  ];

  const values = [
    {
      imageURL: value_01,
      title: "Nhà trường",
      items: [
        {
          title:
            "Nâng cao chất lượng giảng dạy với học liệu sinh động và hiệu quả",
        },
        {
          title: "Đồng bộ với khung chương trình mầm non của Bộ Giáo dục.",
        },
        {
          title: "Duy trì sự hiện đại trong giảng dạy.",
        },
        {
          title: "Tăng cường tương tác giữa học sinh và giáo viên",
        },
        {
          title: "Giảm chi phí phát triển nội dung mới",
        },
      ],
    },
    {
      imageURL: value_02,
      title: "Giáo viên",
      items: [
        {
          title: "Công cụ giảng dạy đa dạng, hấp dẫn",
        },
        {
          title: "Giảm áp lực chuẩn bị tài liệu với hệ thống bài học có sẵn",
        },
        {
          title: "Tương tác hiệu quả hơn với trẻ",
        },
        {
          title: "Cá nhân hóa giản dạy theo nhu cầu từng trẻ",
        },
      ],
    },
    {
      imageURL: value_03,
      title: "Phụ huynh",
      items: [
        {
          title: "Đồng hành cùng con học tại nhà dễ dàng",
        },
        {
          title: "Hiểu rõ tiến độ học tập của con và hỗ trợ học tập hiệu quả",
        },
        {
          title: "Tăng kết nối gia đình với những trải nghiệm thú vị",
        },
        {
          title: "Tiết kiệm thời gian và công sức tìm kiếm",
        },
      ],
    },
    {
      imageURL: value_04,
      title: "Học sinh",
      items: [
        {
          title: "Khơi dậy niềm vui và hứng thú học tập cho bé.",
        },
        {
          title:
            "Phát triển toàn diện về kiến thức, kỹ năng, ngôn ngữ và sáng tạo",
        },
        {
          title: "Cung cấp môi trường học tập vui vẻ, bổ ích",
        },
        {
          title: "Định hướng tự học và phát triển tư duy",
        },
      ],
    },
  ];
  return (
    <div className="bg-[#F8F8F8] flex-row">
      {/* Header and section 1 */}
      <div className="bg-[#142548] w-full">
        <div className="">
          <Header />
        </div>
        {/* Section 1 */}
        <div className="flex justify-end pv:max-lg:items-center pv:max-lg:flex-col pv:max-lg:gap-5 pv:max-lg:mt-0 items-end gap-28 mt-10">
          <div className="w-[45%] pv:max-lg:w-[75%] pv:max-lg:flex-col pv:max-lg:align-middle pv:max-lg:text-center">
            <p className="font-[800] text-[80px] dv:text-[100px] text-[#F56177] pv:max-lg:text-[60px] pv:max-lg:mt-5 mt-36 mb-2 dv:mb-4 pv:max-lg:text-center">
              GIỚI THIỆU
            </p>
            <p className="text-justify text-[22px] dv:text-[30px] text-white font-[400] leading-[28.31px] dv:leading-[36.31px] pv:max-lg:text-[18px] pv:max-lg:leading-[24px]">
              Chào mừng đến với bộ học liệu độc quyền Whykids - nơi mỗi bài học
              là một hành trình khám phá đầy kỳ thú! Từ những video bài hát vui
              nhộn đến hoạt hình sống động, Whykids giúp trẻ vừa học vừa chơi,
              hấp thụ kiến thức một cách tự nhiên, vui vẻ. Được phát triển từ
              khung chương trình mầm non chuẩn Bộ Giáo dục, mỗi nội dung đều
              được chăm chút kỹ lưỡng, đảm bảo an toàn và chất lượng. Hơn thế
              nữa, các hoạt động tương tác sáng tạo sẽ biến việc đồng hành cùng
              trẻ của giáo viên và phụ huynh trở nên dễ dàng, thú vị và tràn
              ngập cảm hứng!
            </p>
            <button
              onClick={scrollToElement}
              className="w-auto px-6 dv:px-8 pv:max-lg:px-5 h-[46px] font-[700] text-[16px] dv:text-[20px] text-white bg-[#F56177] rounded-full mt-4 mb-12 pv:max-lg:mx-auto"
            >
              Khám phá ngay
            </button>
          </div>

          <div className="w-[40%] pv:max-lg:w-[80%] flex align-bottom">
            <img
              src="/library_img/intro/lady.png"
              className="w-full h-full object-contain"
              alt="lady_welcome"
            />
          </div>
        </div>
      </div>
      {/* Section 2 */}
      <div className="mt-8 w-full ">
        <h2 className=" w-[100%] text-[#142548] font-[800] pv:max-md:text-[40px] text-[80px] dv:text-[100px] text-center">
          ĐẶC ĐIỂM NỔI BẬT
        </h2>
        <div className="flex justify-center px-10">
          <div className="w-[100%] flex flex-wrap gap-8 justify-center items-center my-8">
            {featureCards.map((feature, index) => {
              return (
                <FlipCard
                  key={index}
                  imageURL={feature.imageURL}
                  title={feature.title}
                  color={feature.color}
                  content={feature.content}
                />
              );
            })}
          </div>
        </div>
      </div>
      {/* Section 3 */}
      <div className="">
        <h2
          className={
            " w-[100%] text-[#142548] pv:max-md:text-[40px] font-[800] text-[80px] dv:text-[100px] text-center"
          }
        >
          {"GIÁ TRỊ MÀ "}
          <br />
          <span className={"text-[#F56177]"}>{"WHYKIDS"}</span>
          {" MANG LẠI"}
        </h2>
        {/* divider */}
        <div className="relative mt-10 h-[80px]">
          <div
            className="h-[2px] bg-[#001d3d] w-[100%] top-[59px]"
            style={{
              position: "absolute",
              zIndex: 1,
            }}
          />
          <div
            className="flex flex-col items-center left-[0%] w-[25%]"
            style={{
              position: "absolute",
              zIndex: 10,
            }}
          >
            <p
              className=" text-[30px] pv:max-md:text-[14px] font-[800] text-[#142548] cursor-pointer h-[50px]"
              onClick={() => {
                setCurrentValue(0);
              }}
            >
              {"Nhà trường"}
            </p>
            <div className=" w-[18px] h-[18px] bg-[#ff6396] rounded-full mb-2" />
          </div>
          <div
            className="flex flex-col items-center left-[25%] w-[25%]"
            style={{
              position: "absolute",
              zIndex: 10,
            }}
          >
            <p
              className=" text-[30px] pv:max-md:text-[14px] font-[800] text-[#142548] cursor-pointer h-[50px]"
              onClick={() => {
                setCurrentValue(1);
              }}
            >
              {"Giáo viên"}
            </p>
            <div className=" w-[18px] h-[18px] bg-[#ff6396] rounded-full mb-2" />
          </div>
          <div
            className="flex flex-col items-center left-[50%] w-[25%]"
            style={{
              position: "absolute",
              zIndex: 10,
            }}
          >
            <p
              className=" text-[30px] pv:max-md:text-[14px] font-[800] text-[#142548] cursor-pointer h-[50px]"
              onClick={() => {
                setCurrentValue(2);
              }}
            >
              {"Phụ huynh"}
            </p>
            <div className=" w-[18px] h-[18px] bg-[#ff6396] rounded-full mb-2" />
          </div>

          <div
            className="flex flex-col items-center left-[75%] w-[25%]"
            style={{
              position: "absolute",
              zIndex: 10,
            }}
          >
            <p
              className=" text-[30px] pv:max-md:text-[14px] font-[800] text-[#142548] cursor-pointer h-[50px]"
              onClick={() => {
                setCurrentValue(3);
              }}
            >
              {"Học sinh"}
            </p>
            <div className=" w-[18px] h-[18px] bg-[#ff6396] rounded-full mb-2" />
          </div>
        </div>
        {/* Slider */}
        <div className="mt-[60px] ">
          <div className="flex justify-center">
            <div className=" w-[80%] h-auto">
              <ValueCard
                nextClick={() => {
                  if (currentValue !== 0) {
                    setCurrentValue(currentValue - 1);
                  } else {
                    setCurrentValue(values.length - 1);
                  }
                }}
                prevClick={() => {
                  if (currentValue < values.length - 1) {
                    setCurrentValue(currentValue + 1);
                  } else {
                    setCurrentValue(0);
                  }
                }}
                imageURL={values[currentValue].imageURL}
                title={values[currentValue].title}
                items={values[currentValue].items}
                // titleStyle={"absolute top-[0px] left-[752px]"}
              />
            </div>
          </div>
        </div>
      </div>{" "}
      {/* End Section 3 */}
      {/* Section 4 */}
      <div
        ref={targetRef}
        className="w-[100%] my-[60px] flex items-center justify-center "
      >
        <div className="bg-[#65AFFF] rounded-[45px] w-[80%] flex justify-center pv:max-lg:flex-col pv:max-lg:items-center">
          {/* Section 4 - Left */}
          <div className="w-[10%]" />
          <div className="w-[45%] pv:max-lg:w-[90%]">
            <h2
              className={
                " text-white font-[800] text-[32px]  dv:text-[52px] leading-[38.6px] dv:leading-[54.6px] mt-9"
              }
            >
              {"Đăng nhập để mở ra thế giới học liệu thú vị cùng Whykids."}
            </h2>
            <form onSubmit={handleLogin}>
              <div className="mt-6 ml-8">
                <label
                  htmlFor="email"
                  className={" w-[153px] h-[19px] text-white text-[20px] mt-10"}
                >
                  {"Email"}
                </label>
              </div>

              <div className="mt-2">
                <input
                  //type="email"
                  id={"email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className={
                    "w-[75%] dv:w-[50%] pv:max-lg:w-full bg-[#9DCBFC] rounded-[32.5px] h-[45px] outline-none text-white px-4 py-2"
                  }
                />
              </div>
              <div className="mt-8 ml-8">
                <label
                  className={"w-[153px] h-[19px] text-white text-[20px] mt-10"}
                >
                  {"Mật khẩu"}
                </label>
              </div>

              <div className="mt-2">
                <input
                  type={"password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className={
                    "w-[75%] dv:w-[50%] pv:max-lg:w-full bg-[#9DCBFC] rounded-[32.5px] h-[45px] outline-none text-white px-4 py-2"
                  }
                />
              </div>
              {error && <p className="mt-2 ml-4 text-red-700">{error}</p>}
              {isLoading && <p className="mt-2 ml-4 text-white">{isLoading}</p>}
              <button
                type="submit"
                className={
                  " mt-6 mb-12 px-10 w-auto bg-[#142548] h-[46px] rounded-[23px] text-white font-[700] leading-[21px] text-[20px] hover:opacity-80"
                }
              >
                {"Đăng nhập"}
              </button>
            </form>
          </div>
          {/* Section 4 - Right */}
          <div className="mt-6 w-[45%] pv:max-lg:w-full pv:max-lg:mt-0 pv:max-lg:items-start bg-red-30 flex justify-end items-end">
            <img className="items-center rounded-[40px]" src={signIn} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default LearningLibraryIntroductionPage;
