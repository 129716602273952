import axiosConfigUser from ".././axiosConfigUser";

export const paymentRequestUser = async (packageId: string, vnp_ReturnUrl: string) => {
    try {
      const response = await axiosConfigUser({
        method:"POST",
        url:"/payment/create",
        data:{
            packageId:packageId,
            vnp_ReturnUrl:vnp_ReturnUrl
        }
      });
      return response.data; 
    } catch (error) {
      return error;
    }
  };