import { useEffect, useState } from "react";

import Footer from "../../components/Common/footer";

import { Banner, SideBar, TransactionHistory } from "../Account";
import Header from "../../components/Common/header/header";
import { getAccountInformation } from "../../services/userServices/authUser.service";

const AccountPage = () => {

  const init = async () => {
    const data = await getAccountInformation();
    console.log(data);
    setUser((prevUser) => ({
      ...prevUser, // Sao chép các thuộc tính cũ của user
      fullName: data.data.userID, // Chỉ thay đổi thuộc tính fullName
    })); };

  useEffect(() => {
    init();
    

  }, []); 
   const [currentSetting, setCurrentSetting] = useState<{
    id: string;
    title: string;
    subSettings: Array<{ id: string; title: string }>;
  }>({
    id: "-1",
    title: "Default",
    subSettings: [],
  });

  const [user,setUser] = useState<{
    avatarURL: string;
    fullName: string;
    phoneNumber: string;
  }>({
    avatarURL: "",
    fullName: "Nguyen Minh Khanh",
    phoneNumber: "123456789",
  });


  const transactions:
    | Array<{
        dateTime: string;
        kindOfPackage: string;
      }>
    | [] = [
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
  ];

  console.log(currentSetting);

  return (
    // <div className="bg-[#F8F8F8]">
    <div className="bg-[#F8F8F8]">
      <div className="mb-8 w-full h-fit bg-cover">
        <div className="absolute z-50 w-full">
          <Header />
        </div>
        <div className="mt-8 px-6 lg:px-24 lg:grid lg:grid-cols-4 lg:gap-4">
          <SideBar
            user={user}
            currentSetting={currentSetting}
            setCurrentSetting={setCurrentSetting}
          />
          <div className="my-8 col-span-3 lg:mt-0">
            <Banner />
            {currentSetting.id === "0" ? (
              <TransactionHistory transactions={transactions} />
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AccountPage;
