import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PaymentHandler: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Kiểm tra URL sau thanh toán, ví dụ có tham số 'status' hoặc 'transactionId'
    const queryParams = new URLSearchParams(location.search);
    const paymentStatus = queryParams.get('vnp_BankTranNo');

    console.log(paymentStatus);

  }, [location, navigate]);

  return <div>Processing payment...</div>;
};

export default PaymentHandler;
