import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Spin } from "antd";
import LearningCard from "../../components/Common/libraryStudy/learningDetailCard";
import ModalLesson from "../../components/Common/libraryStudy/modalLesson";
import { LoadingOutlined } from "@ant-design/icons";
import { getLessonByIslandID } from "../../services/lesson.service";
import { getAllIsland } from "../../services/island.service";
import { useGameContext } from "../../contexts/gameContext";

interface Video {
  videoUrl: string;
  thumbnailUrl: string;
}

interface LessonInfo {
  isLandName: any;
  ID: string;
  lessonTitle?: string;
  schoolSectionTitle?: string;
  target?: string;
  subjectId?: string;
  description?: string;
  age?: string;
  videos?: Video[];
  games?: string[];
  data?: any;
  colors?: string[];
}

type IslandInfo = {
  ID: string;
  colors: string[];
  image: string;
  title: string;
  key: string;
  ItemMenu: ItemMenu[];
};

type ItemMenu = {
  key: number;
  label: string;
};

function LearningLibrary() {
  const navigate = useNavigate();
  const [infoList, setInfoList] = useState<IslandInfo[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [lessonData, setLessonData] = useState<LessonInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    lessonTitle: "",
    videoURl: "",
    thumbnailUrl: "",
    age: "",
    targetVideo: "",
    thumnailGameList: [],
  });
  const { fetchGames } = useGameContext();

  // Input change handler
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  // Load lesson data
  const loadIslandLesson = async () => {
    try {
      setLoading(true);
      const lessonsPromises = [
        getLessonByIslandID("clt2orykx00036mjf4a434oms").then((result) => ({
          ...result,
          isLandName: "Kỹ năng xã hội",
        })),
        getLessonByIslandID("clt2opn1v00016mjf5vyw4t35").then((result) => ({
          ...result,
          isLandName: "Toán học",
        })),
        getLessonByIslandID("clt2oob6t00006mjfv54wljdl").then((result) => ({
          ...result,
          isLandName: "Tiếng Việt",
        })),
        getLessonByIslandID("clt2oqko600026mjfqz0p5mhv").then((result) => ({
          ...result,
          isLandName: "Văn hóa dân gian",
        })),
      ];

      const results = await Promise.all(lessonsPromises);

      const allLesson = results.flatMap((result: LessonInfo) => {
        const island = infoList.find(
          (info) => info.title.toLowerCase() === result.isLandName.toLowerCase()
        );
        return result.data.map((lesson: any) => ({
          ...lesson,
          isLandName: result.isLandName,
          colors: island ? island.colors : [],
        }));
      });

      console.log(allLesson, "allLesson");

      setLessonData(allLesson);
    } catch (error) {
      console.error("Error loading lessons:", error);
    } finally {
      setLoading(false);
    }
  };
  // open modal
  const handleCardClick = (lesson: any) => {
    const videoUrl = lesson.videos?.[0]?.videoUrl || "";
    const thumbnailUrl = lesson.videos?.[0]?.thumbnailUrl || "";
    setModalContent({
      lessonTitle: lesson.lessonTitle || "",
      videoURl: videoUrl,
      thumbnailUrl: thumbnailUrl,
      age: lesson.age || "",
      targetVideo: lesson.target || "",
      thumnailGameList: lesson.games || [],
    });
    setIsModalVisible(true);
  };
  // close modal
  const handleCancelModal = () => {
    setIsModalVisible(false);
  };
  // Initialize the data
  const init = async () => {
    await fetchGames();
    const dataIsland = await getAllIsland();
    setInfoList(dataIsland.data);
  };

  useEffect(() => {
    if (infoList.length > 0) {
      loadIslandLesson();
    }
  }, [infoList]);

  useEffect(() => {
    init();
  }, []);

  // Filter lessons based on the input value
  const filteredLessons = lessonData.filter(
    (lesson) =>
      lesson.lessonTitle?.toLowerCase().includes(inputValue.toLowerCase()) ||
      lesson.target?.toLowerCase().includes(inputValue.toLowerCase()) ||
      lesson.schoolSectionTitle
        ?.toLowerCase()
        .includes(inputValue.toLowerCase()) ||
      lesson.isLandName.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <div className="bg-[#F9FEFF]">
      <div className="flex justify-center">
        <img
          className="w-[80%]"
          src="library_img/header/school_mark_2.png"
          alt=""
        />
      </div>
      <div
        className="relative"
        style={{
          backgroundImage: `url("/background/bg_for_school_repeat.png")`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "contain",
        }}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-end w-[90%]">
            <Input
              variant={"borderless"}
              className="w-[400px] h-[70px] rounded-full bg-[#D1E7FF] px-10 text-[#65AFFF] uppercase text-[24px] font-bold flex items-center"
              placeholder="TÌM KIẾM HỌC LIỆU"
              prefix={
                <img
                  src="/logo/search_blue.png"
                  alt="Search Icon"
                  className="w-7 h-7 object-contain mr-4"
                />
              }
              onChange={handleInputChange}
            />
          </div>

          <div className="w-[90%] py-1 mt-10">
            <div className="flex justify-between gap-[30px] pv:max-xl:flex-wrap pv:max-xl:justify-center">
              {loading ? (
                <div className="flex justify-center w-full items-center mt-10">
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 130 }} spin />
                    }
                  />
                </div>
              ) : inputValue === "" ? (
                infoList.map((info, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      navigate(`../library/detail-${info.key}`, {
                        state: { data: info },
                      })
                    }
                    className="w-[394px] md:max-lg:w-[330px] lg:max-xl:w-[400px] sm:max-md:w-[280px] transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300 h-[522px] pv:max-xl:h-auto py-10 px-4 flex-col bg-white rounded-[20px]"
                    style={{
                      boxShadow: "0px 4.58px 11.45px rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <img
                      className="px-5 w-full object-contain h-[80%] sm:max-md:h-[60%]"
                      src={info.image}
                      alt={info.title}
                    />
                    <div className="flex justify-center items-center">
                      <p className="text-[40px] text-[#65AFFF] text-center uppercase font-bold">
                        {info.title.split(" ").slice(0, 2).join(" ")}
                        <br />
                        {info.title.split(" ").slice(2).join(" ")}
                        {/* {info.title} */}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-[100%] flex justify-items-stretch flex-wrap gap-5">
                  {filteredLessons.length > 0 ? (
                    filteredLessons.map((lesson, index) => {
                      return (
                        <LearningCard
                          key={index}
                          colorCardLv1={lesson.colors![0]}
                          colorCardLv2={lesson.colors![1]}
                          colorCardLv3={lesson.colors![2]}
                          handleClick={() => handleCardClick(lesson)}
                          lessonTitle={lesson.lessonTitle}
                          age={lesson.age}
                          videoURl={lesson.videos![0]?.videoUrl || ""}
                          // thumnail={lesson.videos![0]?.thumbnailUrl || ""}
                          thumnail={
                            lesson.videos![0]?.thumbnailUrl ||
                            "/library_img/icon/no-thumbnail.jpg"
                          }
                          thumnailGame={lesson.games}
                          titleVideo={lesson.target}
                        />
                      );
                    })
                  ) : (
                    <div className="w-full">
                      <p className="text-red-500 text-xl font-bold text-center ">
                        Không tìm thấy bài học nào phù hợp với từ khóa của bạn.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <img
          className="w-full mt-10"
          src="library_img/footer/footer_library.png"
          alt=""
        />
      </div>
      {/* Modal Component */}
      <ModalLesson
        isModalVisible={isModalVisible}
        handleCancel={handleCancelModal}
        videoURl={modalContent.videoURl}
        listThumnaiGamelURl={modalContent.thumnailGameList}
        data={modalContent}
      />
    </div>
  );
}

export default LearningLibrary;
