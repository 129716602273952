import { useState } from "react";
import backgroundImg from "../../assets/login/bg_login.png";
import tagLogin from "../../assets/login/login_animal_tag.png";
import Header from "../../components/Common/header/header";
import { useNavigate } from "react-router-dom";
import { loginUser, verifyOTPUser } from "../../services/userServices/authUser.service";



const LoginPage = () => {
  const [username, setUsername] = useState<string>("");
  const [session, setSession] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [otpProcess, setOtpProcess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleLoginUser_P1 = async () => {
    setIsLoading("Mã OTP sẽ được gửi đến email/số điện thoại của bạn.");
    if (isLoading == null) {
      const data = await loginUser(username);
      console.log(data);

      if (data != "Fail") {
        setIsLoading(null);
        setSession(data.data.session_id);
        setOtpProcess(true);
      } else {
        setIsLoading(null);
      }
    }
  }

  const handleLoginUser_P2 = async () => {
    setIsLoading("Đang kiểm tra đăng nhập, vui lòng đợi giây lát...");
    if (isLoading == null) {
      const data = await verifyOTPUser(session, otp);
      console.log(data);

      if (data != "Fail") {
        setIsLoading(null);
        setOtpProcess(false);
        sessionStorage.setItem("accessTokenUser", data.data.token.accessToken);
        sessionStorage.setItem("refreshTokenUser", data.data.token.refreshToken);
        navigate("/");
      } else {
        setIsLoading(null);
        setOtpProcess(false);
      }
    }
  }

  return (
    <div className="">
      <div
        className="w-[100%] h-[800px] sm:h-[1000px]"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute z-50 w-full">
          <Header />
        </div>

        <div className="w-[100%] h-[700px] justify-center flex pt-36">
          <img
            className="items-center"
            src={tagLogin}
            alt=""
            style={{ position: "absolute", zIndex: 1 }}
          />
          
            <div className="w-full mx-4 pvmax:mx-8 md:mx-0 ph:w-[531px] h-[360px] sm:h-[423px] bg-[#65AFFF] rounded-[30px] mt-[230px] pv:mt-[250px] pvmax:mt-[280px] ph:mt-[280px] px-5 pvmax:px-8 sm:px-12 pt-6">
              <p className="font-bold text-[#FFFFFF] text-[28px] sm:text-[48px] mt-4">
                Đăng nhập
              </p>
              <p className=" text-[#FFFFFF] text-[20px] mt-2 sm:mt-6">
                {otpProcess?"Vui lòng nhập mã OTP được gửi về email/số điện thoại:":"Số điện thoại / Email:"}
              </p>
              <input onChange={(e)=>otpProcess?setOtp(e.target.value): setUsername(e.target.value)}
              value={otpProcess?otp:username}
                className=" w-full h-12 bg-[#9DCBFC] rounded-full mt-2 sm:mt-5 px-4 text-[#FFFFFF] font-semibold"
                placeholder={otpProcess?"Mã OTP":"Số điện thoại/Email"}
              ></input>
              {error && <p className="mt-2 ml-4 text-red-700">{error}</p>}
              {isLoading && <p className="mt-2 ml-4 text-white text-[18px] italic">{isLoading}</p>}
              <button onClick={otpProcess?handleLoginUser_P2:handleLoginUser_P1} className=" w-[80%] pvmax:w-[182px] flex h-[42.96px] bg-[#142548] items-center justify-center rounded-full mt-2 sm:mt-5">
                <p className=" text-[#FFFFFF] text-[18px] font-semibold">
                  {!otpProcess ? "Gửi mã OTP" : "Đăng nhập"}
                </p>
              </button>
              <div className="hidden mt-5 sm:flex">
                <a href="/term" className=" text-[#142548] text-[18px]">
                  Điều khoản sử dụng
                </a>
                &nbsp;
                <span className=" text-[#FFFFFF] text-[20px]">và</span>&nbsp;
                <a href="/policy" className=" text-[#142548] text-[18px]">
                  Chính sách bảo mật
                </a>
              </div>
              <div className=" mt-5 flex sm:hidden">
                <a href="/term" className=" text-[#142548] text-[18px]">
                  Thông tin điều khoản
                </a>
                &nbsp;
                <span className=" text-[#FFFFFF] text-[18px]">và</span>&nbsp;
              </div>
              <a
                href="/policy"
                className=" flex sm:hidden text-[#142548] text-[18px]"
              >
                Chính sách bảo mật
              </a>
            </div>
          

        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default LoginPage;
