import React, { useState } from "react";
import QRCodeModal from "./QRCodeModal";
import CreditModal from "./creditModal";

const OptionPaymentRadioButton: React.FC = () => {
  const [value, setValue] = useState<number | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const paymentMethods = [
    { id: 1, label: "QR" },
    { id: 2, label: "Thẻ tín dụng" },
  ];

  const onChange = (id: number) => {
    setValue(id);
    setIsModalVisible(true); // Show modal when an option is selected
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="w-full">
      {paymentMethods.map((method) => (
        <label
          key={method.id}
          className={`custom-radio flex justify-between items-center border-2 border-[#142548] w-full my-2 rounded-[34px] p-4 cursor-pointer transition-all duration-300 ease-in-out text-[#142548]"`}
          onClick={() => onChange(method.id)}
        >
          <div className="flex justify-between w-full items-center">
            <div className="flex items-center">
              <div
                className={`h-6 w-6 rounded-full border-2 flex justify-center items-center transition-all duration-300 ease-in-out ${
                  value === method.id
                    ? "border-[#142548] bg-[#142548] scale-110"
                    : "border-[#142548]"
                }`}
              >
                <div
                  className={`transition-all duration-300 ease-in-out ${
                    value === method.id
                      ? "h-3 w-3 bg-white rounded-full scale-100"
                      : "h-6 w-6 bg-[#142548] rounded-full scale-100"
                  }`}
                />
              </div>
              <p className="ml-4 text-[20px] font-semibold">{method.label}</p>
            </div>
          </div>
        </label>
      ))}

      {/* Conditional Modal Rendering */}
      {isModalVisible && (
        <div className="">
          {/* Conditionally render QRCodeModal or CreditModal */}
          {value === 1 ? (
            <QRCodeModal handleCancel={closeModal} />
          ) : (
            <CreditModal handleCancel={closeModal} />
          )}
        </div>
      )}
    </div>
  );
};

export default OptionPaymentRadioButton;
