import React from "react";

interface QRCodeProps {
  handleCancel: () => void;
}

const steps = [
  {
    imgSrc: "/logo/QRLogo/step1.png",
    stepTitle: "Bước 1:",
    stepDescription: "Mở ứng dụng ngân hàng trên điện thoại",
  },
  {
    imgSrc: "/logo/QRLogo/step2.png",
    stepTitle: "Bước 2:",
    stepDescription: "Chọn tính năng quét mã trong ứng dụng",
  },
  {
    imgSrc: "/logo/QRLogo/step3.png",
    stepTitle: "Bước 3:",
    stepDescription: "Sử dụng trình quét mã để quét mã QR",
  },
];

const QRCodeModal = ({ handleCancel }: QRCodeProps) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative">
        <div className="flex gap-6 bg-white rounded-[40px]">
          {/* Left section */}
          <div className="w-[335px] bg-[#142549] rounded-s-[30px] py-10 px-10 flex flex-col justify-center items-center">
            <div className="bg-white w-[247px] h-[247px] rounded-[30px] flex flex-col items-center gap-2 py-7 px-7">
              <p className="text-[18px] text-[#142549] font-semibold text-center">
                Mã QR
              </p>
              <div className="bg-[#D9D9D9] h-[170px] w-[170px]" />
            </div>
            <p className="text-white mt-4 font-semibold text-[18px]">
              Thời gian còn lại: 00:00
            </p>
          </div>

          {/* Right section */}
          <div className="w-[70%] rounded-e-[30px] flex flex-col justify-center items-center bg-white px-8 py-6">
            <p className="text-[#142549] font-bold text-[35px] mb-4 text-center">
              Quét mã QR để thanh toán
            </p>
            <div className="space-y-4">
              {steps.map(({ imgSrc, stepTitle, stepDescription }, index) => (
                <div key={index} className="flex items-center gap-4">
                  <img
                    className="h-[41px] object-contain"
                    src={imgSrc}
                    alt={`Step ${index + 1}`}
                  />
                  <div className="text-[#142549]">
                    <p className="text-[20px] font-bold">{stepTitle}</p>
                    <p className="text-[18px] font-medium">{stepDescription}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Close button */}
        <button
          onClick={handleCancel}
          className="absolute -top-5 -right-[50px] hover:text-gray-400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default QRCodeModal;
