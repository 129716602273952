import React from "react";
import HeaderDesktop from "./headerDesktop";
import HeaderMobile from "./headerMobile";
interface HeaderProps {
  searchTextChangeColor?: boolean; // tùy chọn (optional)
}

function Header({ searchTextChangeColor = false }: HeaderProps) {
  return (
    <div>
      <div className="pv:max-dv:hidden w-full ">
        <HeaderDesktop searchTextChangeColor={searchTextChangeColor} />
      </div>
      <div className="dv:max-dh:hidden ">
        <HeaderMobile />
      </div>
    </div>
  );
}

export default Header;
