import React, { useEffect, useRef, useState } from "react";
import { useGameContext } from "../../../contexts/gameContext";

interface LearningCardProps {
  data?: any;
  lessonTitle?: string;
  videoURl?: string;
  thumnail?: string;
  titleVideo?: string;
  age?: string;
  thumnailGame?: string[]; // Assumed to be an array of image URLs
  colorCardLv1: string;
  colorCardLv2: string;
  colorCardLv3: string;
  handleClick?: (videoTime: number) => void;
}

function LearningCard({
  lessonTitle,
  videoURl,
  titleVideo,
  thumnail,
  age,
  thumnailGame = [], // Ensure it's an empty array if undefined
  colorCardLv1,
  colorCardLv2,
  colorCardLv3,
  handleClick,
}: LearningCardProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentTime, setCurrentTime] = useState(0);

  const { gamesData } = useGameContext();

  const capitalizeFirstLetter = (content: string | undefined) => {
    return content
      ? content.charAt(0).toUpperCase() + content.slice(1).toLowerCase()
      : "";
  };

  const listGames = gamesData.filter((game) =>
    thumnailGame?.includes(game.key)
  );

  // Handle video pause and update the current time
  const handlePause = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
      videoRef.current.pause();
    }
  };

  // Handle right-click prevention on the video element
  const handleRightClick = (event: React.MouseEvent<HTMLVideoElement>) => {
    event.preventDefault();
  };

  // Wrapper to handle the click and send the video time to the parent component
  const handleClickWrapper = () => {
    if (handleClick) handleClick(currentTime);
  };

  // Dynamically set background color based on age
  const backgroundColor =
    age === "5-6" ? colorCardLv3 : age === "3-6" ? colorCardLv2 : colorCardLv1;

  return (
    <button
      onClick={handleClickWrapper}
      className="flex flex-col w-fit h-fit rounded-[17px] transition-transform duration-300 px-6 py-4 hover:scale-110"
      style={{
        backgroundColor,
        boxShadow: "0 5px 10px rgba(0, 0, 0, 0.3)",
      }}
    >
      <div className="h-full">
        <p className="uppercase font-bold text-[#142548] text-[19px] text-start">
          {lessonTitle}
        </p>
        <div className="flex gap-5 mt-3">
          {/* Left - Video Section */}
          <div className="w-[210px]">
            <div className="relative">
              <video
                onContextMenu={handleRightClick}
                ref={videoRef}
                className="h-[137px] w-full rounded-[6px] object-fill"
                controlsList="nodownload"
                poster={thumnail?.replace(
                  "http://34.49.7.176",
                  "https://storage.googleapis.com/why_kid_static_content"
                )}
              >
                <source
                  src={videoURl?.replace(
                    "http://34.49.7.176",
                    "https://storage.googleapis.com/why_kid_static_content"
                  )}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="mt-3 flex items-center gap-3 text-[#142548]">
              <p className="w-[80px] text-[27px] font-[800]">{age}</p>
              <div className="h-[30px] w-[2px] bg-[#142548]" />
              <p className="w-[190px] font-bold text-[12px] text-left">
                {capitalizeFirstLetter(titleVideo)}
              </p>
            </div>
          </div>
          {/* Right - Image Grid Section */}
          <div className="w-[135px]">
            <div className="h-[137px] w-full grid grid-cols-2 gap-2 justify-items-center rounded-[6px]">
              {listGames.map((game, i) => (
                <div key={i} className="rounded-[6px] overflow-hidden">
                  <img
                    src={game.thumbnailUrl.replace(
                      "http://34.49.7.176",
                      "https://storage.googleapis.com/why_kid_static_content"
                    )}
                    alt={game.key}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
              {!listGames || listGames.length > 2 ? (
                <div />
              ) : (
                <div className="h-[64.5px] w-[63.5px]  rounded-[6px] overflow-hidden">
                  <div className="h-[64.5px] w-[63.5px]  rounded-[6px] overflow-hidden bg-transparent"></div>
                  <div className="h-[64.5px] w-[63.5px] rounded-[6px] overflow-hidden bg-transparent"></div>
                </div>
              )}
            </div>
            <p className="mt-5 font-bold text-[12px] text-center text-[#142548] capitalize">
              Hoạt động hỗ trợ
            </p>
          </div>
        </div>
      </div>
    </button>
  );
}

export default LearningCard;
